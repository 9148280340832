import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { Grid } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import InventoryCategoryGridData from './components/InventoryCategoryGridData'
import InventoryCategoryGridFilter from './components/InventoryCategoryGridFilter'
import { getInventoryCategoriesList } from 'app/redux/store/features/definitions'
import { LoadPanel } from 'devextreme-react/load-panel'
import Div from '@jumbo/shared/Div'

const InventoryCategory = () => {
    const [filter, setFilter] = useState({
        categoryName: null,
        sort: 'CategoryName',
        isDescending: false,
    })
    const pageSize = 25
    const position = { of: '#dataGrid' }
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const dispatch = useDispatch()
    const { inventoryCategories, refreshing } = useSelector((state) => state.definitions)

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getInventoryCategoriesList({ ...filter, skip: 0, take: pageSize }))
    }, [dispatch])

    function init(initialValue) {
        return {
            gridFilterVisible: initialValue.gridFilterVisible,
            filterIconColor: initialValue.filterIconColor,
        }
    }

    function InvCategoryReducer(state, action) {
        switch (action.type) {
            case 'set-grid-filter':
                return {
                    ...state,
                    gridFilterVisible: action.payload,
                }
            case 'set-filter-icon-color':
                return {
                    ...state,
                    filterIconColor: action.payload,
                }

            case 'set-grid-store':
                return {
                    ...state,
                    gridStore: action.payload,
                }
            default:
                return state
        }
    }

    const [invCategoryList, setInvCategoryList] = React.useReducer(
        InvCategoryReducer,
        {
            gridFilterVisible: false,
            filterIconColor: false,
        },
        init
    )

    const applyFilter = useMutation({
        mutationFn: (currentfilter) => {
            dispatch(getInventoryCategoriesList({ ...currentfilter, skip: 0, take: pageSize }, true))
            setFilter(currentfilter)
        },
    })

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getInventoryCategoriesList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getInventoryCategoriesList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getInventoryCategoriesList({ ...filter, skip: 0, take: pageSize }, true))
        setCurrentPage(0)
    }

    const setGridFilter = React.useCallback(
        (value) => {
            setInvCategoryList({ type: 'set-grid-filter', payload: value })
        },
        [setInvCategoryList]
    )

    const setFilterIconColor = React.useCallback(
        (value) => {
            setInvCategoryList({ type: 'set-filter-icon-color', payload: value })
        },
        [setInvCategoryList]
    )

    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'common.white'} variant={'h3'}>
                    <AutorenewRoundedIcon
                        onClick={onRefresh}
                        fontSize={'large'}
                        sx={{
                            mb: -1.2,
                            mr: 0.5,
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                    Inventory Categories
                </Typography>
            }
            headerSx={{
                height: 40,
                bgcolor: 'primary.main',
                pl: 1,
            }}
            action={
                <Stack direction={'row'} spacing={4} sx={{ mt: -2 }}>
                    <FilterAltIcon
                        fontSize='large'
                        sx={{
                            color:
                                invCategoryList.filterIconColor && !invCategoryList.gridFilterVisible
                                    ? 'success.main'
                                    : 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => setGridFilter(!invCategoryList.gridFilterVisible)}
                    />
                </Stack>
            }>
            <Grid>
                <InventoryCategoryGridFilter
                    mutation={applyFilter}
                    display={invCategoryList.gridFilterVisible ? '' : 'none'}
                    filterIconColor={(data) => setFilterIconColor(data)}
                />
            </Grid>
            <Div id='dataGrid'>
                <Grid>
                    <InventoryCategoryGridData
                        onPageChange={onPageChange}
                        onColumnSort={onColumnSort}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        apiCallInProgress={apiCallInProgress}
                        setApiCallInProgress={setApiCallInProgress}
                    />
                </Grid>
                {inventoryCategories.totalCount !== 0 && (
                    <LoadPanel shadingColor='rgba(0,0,0,0.4)' position={position} visible={refreshing} />
                )}
            </Div>
        </JumboCardQuick>
    )
}

export default InventoryCategory
