export const defaultTransfer = {
    transferNo: '',
    isSourceBranch: 0,
    barcode: '',
    comments: '',
    refNumber: '',
    fkTransferRequestId: null,
    hasShippingCost: false,
    shippingCost: 0,
    shippingComments: '',
    fkSecondBranchId: null,
}
