import React, { useEffect, useMemo, useState } from 'react'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useDispatch, useSelector } from 'react-redux'
import { getTransferAuditLogsList } from 'app/redux/store/features/transfers'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { CustomPaging, PagingState, SortingState, SelectionState, DataTypeProvider } from '@devexpress/dx-react-grid'
import { Grid, Table, TableHeaderRow, PagingPanel, VirtualTable } from '@devexpress/dx-react-grid-material-ui'
import { Paper } from '@mui/material'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import useJumboTheme from '../../../../../../../../../../@jumbo/hooks/useJumboTheme'
import { useMediaQuery } from '@mui/material'
const TransferActivityLogGrid = ({ transferInventoryId, activityLogsData }) => {
    const pageSize = 10
    const [currentPage, setCurrentPage] = useState(0)
    const [filter, setFilter] = useState({
        transferInventoryId: transferInventoryId,
    })
    const dispatch = useDispatch()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [sorting, setSorting] = useState([{ columnName: 'sendBranchName', direction: 'asc' }])
    const dateColumns = ['createdDate', 'updatedDate']
    const { loading, error } = useSelector((state) => state.transfers)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getTransferAuditLogsList({ ...filter, skip: 0, take: pageSize }))
    }, [])

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getTransferAuditLogsList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getTransferAuditLogsList(currentFilter))
        setFilter(currentFilter)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const Cell = (props) => {
        let style = {
            height: '42px',
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const columns = [
        { name: 'createdDate', title: 'Log Date' },
        { name: 'sendBranchName', title: 'Sender Branch' },
        { name: 'receivingBranchName', title: 'Receiving Branch' },
        { name: 'prvAuditStatus', title: 'Prev Status' },
        { name: 'auditStatus', title: 'New Status' },
        { name: 'auditByStaffName', title: 'Activity By' },
        { name: 'message', title: 'Message' },
    ]

    const columnWidth = useMemo(
        () => [
            {
                columnName: 'createdDate',
                width: min ? '130px' : '15%',
                minWidth: '130px',
                wordWrapEnabled: true,
            },
            { columnName: 'sendBranchName', wordWrapEnabled: true, width: min ? '130px' : '13%', minWidth: '130px' },
            {
                columnName: 'receivingBranchName',
                wordWrapEnabled: true,
                width: min ? '120px' : '12%',
                minWidth: '120px',
            },
            { columnName: 'prvAuditStatus', wordWrapEnabled: true, width: min ? '110px' : '11%', minWidth: '110px' },
            {
                columnName: 'auditStatus',
                wordWrapEnabled: true,
                width: min ? '130px' : '13%',
                minWidth: '130px',
            },
            { columnName: 'auditByStaffName', width: min ? '120px' : '12%', minWidth: '120px', wordWrapEnabled: true },
            { columnName: 'message', width: min ? '340px' : '24%', minWidth: '340px' },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [
        { columnName: 'prvAuditStatus', sortingEnabled: false },
        { columnName: 'auditStatus', sortingEnabled: false },
        { columnName: 'auditByStaffName', sortingEnabled: false },
    ]

    return (
        <JumboCardQuick
            noWrapper
            headerSx={{
                height: 'revert !important',
                bgcolor: 'secondary.main',
                pl: 1,
            }}>
            <Paper style={{ position: 'relative' }}>
                <Grid rows={activityLogsData.data} columns={columns}>
                    <DateTypeProvider for={dateColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} />
                    <CustomPaging totalCount={activityLogsData.totalCount} />
                    <Table columnExtensions={columnWidths} cellComponent={Cell} />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <PagingPanel />
                </Grid>
            </Paper>
        </JumboCardQuick>
    )
}

export default TransferActivityLogGrid
