import React, { useEffect, useState, useCallback, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Link, Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { getTransactionById } from 'app/redux/store/features/transactions'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import useJumboTheme from '../../../../../../../@jumbo/hooks/useJumboTheme'

const AllTransactionsData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { transactions, loading, error } = useSelector((state) => state.transactions)
    const dateColumns = ['txnStartDate', 'txnEndDate']
    const [sorting, setSorting] = useState([{ columnName: 'txnStartDate', direction: 'desc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getTransactionById({ posTxId: id, onlineTxn: false }))
        navigate(`/store/sales/all-transaction/details/${id}`, { state: { posTxId: id } })
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.posTxId)}
                sx={{
                    '&:hover': {
                        color: 'action.active', // Change this to the color you want on hover
                        cursor: 'pointer',
                    },
                }}>
                {row.txnNumber}
            </Link>
        )
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.posTxId)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />
    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onNavigatingToDetail(row.posTxId)}>
                    <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const customercellRender = (data) => {
        return data.customerFirstName + ' ' + data.customerLastName
    }

    const columns = [
        { name: 'txnNumber', title: 'Txn No', getCellValue: renderLinkCell },
        { name: 'txnStartDate', title: 'Txn Date' },
        { name: 'customerFirstName', title: 'Customer', getCellValue: customercellRender },
        { name: 'discount', title: 'Discount', getCellValue: (row) => formatCurrency(row.discount) },
        { name: 'txnTotal', title: 'Txn Total', getCellValue: (row) => formatCurrency(row.txnTotal) },
        {
            name: 'totalPayment',
            title: 'Total Payment',
            getCellValue: (row) => formatCurrency(row.totalPayment),
        },
        { name: 'txnStatus', title: 'Txn Status' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'txnNumber', width: min ? '100px' : '10%', minWidth: '100px', wordWrapEnabled: true },
            { columnName: 'txnStartDate', width: min ? '130px' : '13%', minWidth: '130px', wordWrapEnabled: true },
            {
                columnName: 'customerFirstName',
                wordWrapEnabled: true,
                width: min ? '200px' : '20%',
                minWidth: '200px',
            },
            { columnName: 'discount', align: 'right', width: min ? '110px' : '11%', minWidth: '110px' },
            {
                columnName: 'txnTotal',
                align: 'right',
                wordWrapEnabled: true,
                width: min ? '100px' : '10%',
                minWidth: '100px',
            },
            {
                columnName: 'totalPayment',
                width: min ? '110px' : '11%',
                minWidth: '110px',
                align: 'right',
                wordWrapEnabled: true,
            },
            { columnName: 'txnStatus', wordWrapEnabled: true, width: min ? '160px' : '16%', minWidth: '160px' },
            { columnName: 'Action', align: 'center', width: min ? '90px' : '9%', minWidth: '90px' },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={transactions.data} columns={columns}>
                    <DateTypeProvider for={dateColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={transactions.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default AllTransactionsData
