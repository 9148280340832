import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { getGlobalConfig, updateGlobalConfig } from 'app/redux/store/features/configs'
import { useMediaQuery } from '@mui/material'

const GlobalConfig = () => {
    const { handleSubmit } = useForm()
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const { global, loading, error } = useSelector((state) => state.config)
    const [configData, setConfigData] = useState({ ...global })
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    useEffect(() => {
        if (!apiCallInProgress || error) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [error, loading])

    useEffect((e) => {
        setApiCallInProgress(true)
        function getGlobal() {
            dispatch(getGlobalConfig())
        }
        getGlobal()
    }, [])

    useEffect(() => {
        setConfigData({
            ...global,
        })
    }, [global])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    function reloadGlobalConfig() {
        dispatch(getGlobalConfig())
    }

    const dialogBoxButtons = () => {
        return (
            <Stack direction={'row'} spacing={2} sx={{ m: 'auto', ml: 10.5, mb: 2 }}>
                <LoadingButton
                    variant='contained'
                    color='secondary'
                    onClick={async () => {
                        await reloadGlobalConfig()
                        hideDialog()
                        setConfigData((prevConfigData) => ({
                            ...prevConfigData,
                            minimumEposBuildVersion: 0,
                        }))
                    }}
                    size='large'
                    sx={{ width: 100 }}>
                    {' '}
                    yes
                </LoadingButton>
                <LoadingButton
                    variant='outlined'
                    color='secondary'
                    onClick={hideDialog}
                    size='large'
                    sx={{ width: 100 }}>
                    {' '}
                    NO
                </LoadingButton>
            </Stack>
        )
    }

    const onUpdateSyncConfig = useCallback(async () => {
        setApiCallInProgress(true)
        const responseData = dispatch(updateGlobalConfig(configData))
        if (responseData) {
            // On global config data updated successful
            enqueueSnackbar('Config data is updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }, [configData, dispatch, enqueueSnackbar])

    const submitButtonOptions = {
        text: 'Save',
        type: 'danger',
        width: 120,
        icon: 'check',
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () =>
            showDialog({
                title: 'You have unsaved changes, do you want to cancel and reload?',
                content: dialogBoxButtons(),
            }),
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Global Config
                    </Typography>
                }
                sx={{ m: 0.5, mt: 1 }}
                headerSx={{
                    height: 40,
                    bgcolor: 'secondary.main',
                }}>
                <form onSubmit={handleSubmit(onUpdateSyncConfig)}>
                    <Form
                        mode='form'
                        formData={configData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}
                        labelLocation={labelLocation}>
                        <GroupItem caption='Configuration' cssClass='form-group-item '>
                            <ColCountByScreen lg={13} md={1} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item-margin' colSpan={6}>
                                <EmptyItem />
                                <SimpleItem dataField='minimumEposBuildVersion' editorType='dxNumberBox'>
                                    <Label text='Minimum Client Version' />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem buttonOptions={submitButtonOptions} horizontalAlignment='right' />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default GlobalConfig
