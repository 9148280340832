import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { addCustomerPayment } from 'app/redux/store/features/customers.js'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import AddDraftPaymentForm from './AddDraftPaymentForm'
import { defaultAddDraftCustomerPayment } from './defaultAddDraftPayment'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import inventoryService from 'app/services/pages/store/inventory/inventoryServices'
import utilServices from 'app/services/util-services'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'

const AddDraftPayment = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { loading, error } = useSelector((state) => state.customers)
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const customerId = location?.state?.customerId || null
    const today = new Date()

    const [draftCustomersPaymentData] = useState({
        ...defaultAddDraftCustomerPayment,
        paymentDate: today,
        fkCustomerId: customerId,
    })
    const [fkCustomerId, setFkCustomerId] = useState(null)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) {
            handleError(error)
        } else {
            enqueueSnackbar('Add Payment successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate(customerId ? '/store/customers' : '/store/customers/draftpayments')
        }
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const ChartAccountLookupList = useCallback(async (searchTerm) => {
        try {
            return await inventoryService.getChartAccountLookupList({ accountName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ChartAccountLookupById = useCallback(async (key) => {
        try {
            return await inventoryService.getChartAccountLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ChartAccountStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'chartAccountId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await ChartAccountLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()
                        return await ChartAccountLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const chartAccountEditorOptions = {
        dataSource: ChartAccountStore,
        displayExpr: 'accountName',
        valueExpr: 'chartAccountId',
        searchEnabled: true,
        showClearButton: true,
    }

    const addDraftPayment = async () => {
        const totalAmount =
            draftCustomersPaymentData?.cashAmount ||
            0 + draftCustomersPaymentData?.othersAmount ||
            0 + draftCustomersPaymentData?.creditCardAmount ||
            0

        if (totalAmount <= 0) {
            enqueueSnackbar('Please enter a valid amount greater than zero', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            return
        }

        setApiCallInProgress(true)

        const finalFkCustomerId = customerId || fkCustomerId

        dispatch(addCustomerPayment({ ...draftCustomersPaymentData, fkCustomerId: finalFkCustomerId }))
    }

    return (
        <React.Fragment>
            <AddDraftPaymentForm
                draftCustomersPaymentData={draftCustomersPaymentData}
                addDraftPayment={addDraftPayment}
                customerId={customerId}
                setFkCustomerId={setFkCustomerId}
                chartAccountEditorOptions={chartAccountEditorOptions}
            />
        </React.Fragment>
    )
}

export default AddDraftPayment
