import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import { Divider } from '@mui/material'
import { DropDownBox } from 'devextreme-react'
import DataGrid, { Selection, Paging, FilterRow } from 'devextreme-react/data-grid'
import { useSnackbar } from 'notistack'
import CustomStore from 'devextreme/data/custom_store'
import customerCategoryService from 'app/services/pages/store/customers/customerCategoryServices'
import { buildFilter } from 'app/utils/appHelpers'
import { useMediaQuery } from '@mui/material'
import { Validator, RequiredRule } from 'devextreme-react/validator'
import utilServices from 'app/services/util-services'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const UpdateDraftPaymentForm = (props) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })
    const { loading } = useSelector((state) => state.customers)
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    const [gridBoxValue, setGridBoxValue] = useState([])
    const [isGridBoxOpened, setIsGridBoxOpened] = useState(false)

    const gridBoxDisplayExpr = useCallback((item) => {
        return item && `${item.firstName + ' ' + item.lastName}, ${item.mobileNo} , ${item.balanceAmount}`
    }, [])

    const customerLookupList = useCallback(async (filter) => {
        try {
            return await customerCategoryService.getCustomerList(filter)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const customerById = useCallback(async (key) => {
        try {
            return await customerCategoryService.getCustomerById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const customerLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    loadMode: 'processed',
                    key: 'customerId',
                    load: async function (loadOptions) {
                        let searchTerm = { skip: 0, take: 10 }
                        if (loadOptions?.filter) {
                            const filter = buildFilter(loadOptions.filter)
                            searchTerm = { ...searchTerm, ...filter }
                        }
                        return await customerLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (utilServices.isNullOrUndefined(key) || key === '') {
                            return {}
                        }
                        return await customerById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const dataGridOnSelectionChanged = useCallback((e) => {
        setGridBoxValue(e.selectedRowKeys)
        setIsGridBoxOpened(false)
    }, [])

    const useSubmitBehaviors = (gridBoxValue.length > 0 && gridBoxValue[0] !== '') || props?.Payment?.fkCustomerId

    const gridColumns = [
        { dataField: 'firstName', caption: 'Name' },
        { dataField: 'mobileNo', width: 150 },
        {
            dataField: 'balanceAmount',
            caption: 'Balance',
            format: (data) => formatCurrency(data),
            width: 80,
            allowFiltering: false,
        },
    ]

    const dataGridRender = useCallback(
        () => (
            <DataGrid
                dataSource={customerLookupStore}
                columns={gridColumns}
                columnAutoWidth={true}
                hoverStateEnabled={true}
                showBorders={true}
                selectedRowKeys={gridBoxValue}
                onSelectionChanged={dataGridOnSelectionChanged}
                height='100%'
                remoteOperations={true}>
                <Selection mode='single' />
                <Paging enabled={false} pageSize={10} />
                <FilterRow visible={true} applyFilter='auto' />
            </DataGrid>
        ),
        [gridBoxValue, dataGridOnSelectionChanged]
    )

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const onGridBoxOpened = useCallback((e) => {
        if (e.name === 'opened') {
            setIsGridBoxOpened(e.value)
        }
    }, [])

    const syncDataGridSelection = useCallback((e) => {
        setGridBoxValue(e.value)
    }, [])

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/store/customers/draftpayments'),
    }

    const validationRules = {
        paymentDate: [{ type: 'required', message: 'Payment Date is required.' }],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color='common.white' variant='primary'>
                        Payment Details
                    </Typography>
                }
                sx={{ m: 0.5, mt: 1 }}
                headerSx={{
                    height: 40,
                    bgcolor: 'secondary.main',
                }}>
                <form onSubmit={handleSubmit(props.updateDraftPayment)}>
                    <Form
                        mode='form'
                        readOnly={props.Payment.paymentStatus === 'Approved'}
                        formData={props.Payment}
                        showColonAfterLabel={false}
                        labelLocation={labelLocation}>
                        <GroupItem caption='Payment Info' cssClass='form-group-item'>
                            <ColCountByScreen lg={2} md={1} sm={1} xs={1} />
                            <GroupItem cssClass='form-group-item-margin'>
                                <EmptyItem />
                                <SimpleItem dataField='fkCustomerId'>
                                    <Label text='Customer' />
                                    <DropDownBox
                                        value={props?.Payment?.fkCustomerId}
                                        opened={isGridBoxOpened}
                                        valueExpr='customerId'
                                        deferRendering={false}
                                        inputAttr={{ 'aria-label': 'Owner' }}
                                        displayExpr={gridBoxDisplayExpr}
                                        placeholder='Select a value...'
                                        showClearButton={true}
                                        dataSource={customerLookupStore}
                                        onValueChanged={syncDataGridSelection}
                                        onOptionChanged={onGridBoxOpened}
                                        contentRender={dataGridRender}
                                        readOnly={true}>
                                        <Validator>
                                            <RequiredRule message='Supplier is required' />
                                        </Validator>
                                    </DropDownBox>
                                </SimpleItem>
                                <SimpleItem
                                    dataField='paymentDate'
                                    editorType='dxDateBox'
                                    validationRules={validationRules.paymentDate}
                                    editorOptions={{
                                        displayFormat: 'shortDateShortTime',
                                        type: 'datetime',
                                        pickerType: 'calendar',
                                        useMaskBehavior: true,
                                    }}>
                                    <Label text='Payment Date' />
                                </SimpleItem>
                                <GroupItem>
                                    <Divider />
                                </GroupItem>
                                <SimpleItem
                                    dataField='fkCashChartAccountId'
                                    editorType='dxSelectBox'
                                    editorOptions={props.chartAccountEditorOptions}>
                                    <Label text='Cash Chart of Account' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='cashAmount'
                                    editorType='dxNumberBox'
                                    editorOptions={{
                                        showSpinButtons: true,
                                        format: {
                                            type: 'fixedPoint',
                                            precision: 2,
                                        },
                                        min: 0,
                                    }}>
                                    <Label text='Cash Amount' />
                                </SimpleItem>
                                <GroupItem>
                                    <Divider />
                                </GroupItem>
                                <SimpleItem
                                    dataField='fkCreditChartAccountId'
                                    editorType='dxSelectBox'
                                    editorOptions={props.chartAccountEditorOptions}>
                                    <Label text='Credit Chart of Account' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='creditCardAmount'
                                    editorType='dxNumberBox'
                                    editorOptions={{
                                        showSpinButtons: true,
                                        format: {
                                            type: 'fixedPoint',
                                            precision: 2,
                                        },
                                        min: 0,
                                    }}>
                                    <Label text='Credit Card Amount' />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem cssClass='form-group-item-margin'>
                                <EmptyItem />
                                <SimpleItem
                                    dataField='fkOtherChartAccountId'
                                    editorType='dxSelectBox'
                                    editorOptions={props.chartAccountEditorOptions}>
                                    <Label text='Other Chart of Account' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='othersAmount'
                                    editorType='dxNumberBox'
                                    editorOptions={{
                                        showSpinButtons: true,
                                        format: {
                                            type: 'fixedPoint',
                                            precision: 2,
                                        },
                                        min: 0,
                                    }}>
                                    <Label text='Other Amount' />
                                </SimpleItem>
                                <GroupItem>
                                    <Divider />
                                </GroupItem>
                                <SimpleItem dataField='paymentReferenceNo'>
                                    <Label text='Payment Reference No' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='comments'
                                    editorType='dxTextArea'
                                    editorOptions={{
                                        height: 90,
                                        placeholder: 'Add your comment',
                                    }}>
                                    <Label text='Comment' />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading || props.Payment.paymentStatus === 'Approved',
                                    icon: loading ? 'refresh' : 'check',
                                    useSubmitBehavior: useSubmitBehaviors,
                                    text: 'Save',
                                    type: 'default',
                                    width: 120,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateDraftPaymentForm
