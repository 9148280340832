import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import Grid from '@mui/material/Grid'
import { getOrderById, selectItemById } from 'app/redux/store/features/orders'
import HeaderCard from './components/headerBar/HeaderCards'
import InfoBar from './components/infoBar/InfoBar'
import { useNavigate } from 'react-router-dom'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import { TabContext, TabPanel } from '@mui/lab'
import OrderItemsListGrid from './components/orderDetailBar/components/OrderItems/OrderItemsListGrid'
import utilServices from 'app/services/util-services'
import { useJumboTheme } from '@jumbo/hooks'
import { useMediaQuery } from '@mui/material'

const OrderView = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { state } = useLocation()
    const { orderId, onlineShipment } = state
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { orders, loading, error } = useSelector((state) => state.orders)
    const order = selectItemById(orders, orderId)
    const txnItemListResponseData = order.inventoryItems || { data: [], totalCount: 0 }
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        if (utilServices.isNullOrUndefined(order.orderId))
            dispatch(getOrderById({ orderId, onlineShipment: onlineShipment }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const [tabTitle, setTabTitle] = React.useState('products')

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    return (
        <Grid>
            <Grid container spacing={3.75}>
                <Grid item xs={12} sm={12} lg={12}>
                    <HeaderCard order={order} onlineShipment={onlineShipment} />
                </Grid>
                <Grid item xs={12} sm={6} lg={12}>
                    <InfoBar order={order} onlineShipment={onlineShipment} />
                </Grid>
                <Grid item xs={12} sm={6} lg={12}>
                    <Grid sx={{ mt: sm ? 3 : '' }}>
                        <TabContext value={tabTitle}>
                            <TabList sx={{ bgcolor: 'divider' }} onChange={(e, newValue) => setTabTitle(newValue)}>
                                <Tab label={'Products'} value={'products'} sx={{ width: '160px' }} />
                            </TabList>
                            <TabPanel
                                value='products'
                                sx={{
                                    mt: '0px',
                                    pt: '0%',
                                    pl: '0%',
                                    pr: '0%',
                                }}>
                                <OrderItemsListGrid
                                    txnItemListResponseData={txnItemListResponseData}
                                    orderId={orderId}
                                    onlineShipment={onlineShipment}
                                />
                            </TabPanel>
                        </TabContext>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OrderView
