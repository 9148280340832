import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import '../../../../../../constants/stylesheet.css'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderList } from 'app/redux/store/features/orders'
import ApprovedOrdersGridFilter from './components/ApprovedOrdersGridFilter'
import ApprovedOrdersData from './components/ApprovedOrdersData'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import { LoadPanel } from 'devextreme-react/load-panel'
import Div from '@jumbo/shared/Div'

const ApprovedOrders = () => {
    const pageSize = 25
    const position = { of: '#dataGrid' }
    const dispatch = useDispatch()
    const [filter, setFilter] = useState({
        shipmentNo: '',
        shipmentStartDate: DateUtils.getStartDate(10).toISOString(),
        shipmentEndDate: DateUtils.getEndDate().toISOString(),
        onlineShipments: false,
        sort: 'ShipmentDate',
        isDescending: true,
    })
    const [currentPage, setCurrentPage] = useState(0)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { orders, refreshing } = useSelector((state) => state.orders)

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getOrderList({ ...filter, skip: 0, take: pageSize }))
    }, [dispatch])

    function init(initialValue) {
        return {
            gridFilterVisible: initialValue.gridFilterVisible,
            filterIconColor: initialValue.filterIconColor,
        }
    }

    function orderReducer(state, action) {
        switch (action.type) {
            case 'set-grid-filter':
                return {
                    ...state,
                    gridFilterVisible: action.payload,
                }
            case 'set-filter-icon-color':
                return {
                    ...state,
                    filterIconColor: action.payload,
                }
            default:
                return state
        }
    }

    const [orderList, setOrderList] = React.useReducer(
        orderReducer,
        {
            gridFilterVisible: false,
            filterIconColor: false,
        },
        init
    )

    const applyFilter = useMutation({
        mutationFn: (currentfilter) => {
            dispatch(getOrderList({ ...currentfilter, skip: 0, take: pageSize }, true))
            setFilter(currentfilter)
        },
    })

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getOrderList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getOrderList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getOrderList({ ...filter, skip: 0, take: pageSize }, true))
        setCurrentPage(0)
    }

    const setGridFilter = React.useCallback(
        (value) => {
            setOrderList({ type: 'set-grid-filter', payload: value })
        },
        [setOrderList]
    )

    const setFilterIconColor = React.useCallback(
        (value) => {
            setOrderList({ type: 'set-filter-icon-color', payload: value })
        },
        [setOrderList]
    )

    return (
        <React.Fragment>
            <Grid margin={1}>
                <JumboCardQuick
                    noWrapper
                    title={
                        <Typography color={'common.white'} variant={'h3'}>
                            <AutorenewRoundedIcon
                                onClick={onRefresh}
                                fontSize={'large'}
                                sx={{
                                    mb: -1.2,
                                    mr: 0.5,
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                            />
                            Orders Details
                        </Typography>
                    }
                    headerSx={{
                        height: 40,
                        bgcolor: 'secondary.main',
                        mt: 1,
                        pl: 1,
                    }}
                    action={
                        <Stack direction={'row'} spacing={4} sx={{ mt: -2 }}>
                            <FilterAltIcon
                                fontSize='large'
                                sx={{
                                    pt: 0,
                                    pb: 0,
                                    color:
                                        orderList.filterIconColor && !orderList.gridFilterVisible
                                            ? 'success.main'
                                            : 'common.white',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() => setGridFilter(!orderList.gridFilterVisible)}
                            />
                        </Stack>
                    }>
                    <Grid>
                        <ApprovedOrdersGridFilter
                            mutation={applyFilter}
                            display={orderList.gridFilterVisible ? '' : 'none'}
                            filterIconColor={(data) => setFilterIconColor(data)}
                        />
                    </Grid>
                    <Div id='dataGrid'>
                        <Grid>
                            <ApprovedOrdersData
                                onRefresh={onRefresh}
                                onPageChange={onPageChange}
                                onColumnSort={onColumnSort}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                apiCallInProgress={apiCallInProgress}
                                setApiCallInProgress={setApiCallInProgress}
                            />
                        </Grid>
                        {orders.totalCount !== 0 && (
                            <LoadPanel shadingColor='rgba(0,0,0,0.4)' position={position} visible={refreshing} />
                        )}
                    </Div>
                </JumboCardQuick>
            </Grid>
        </React.Fragment>
    )
}
export default ApprovedOrders
