import React, { useCallback, useState } from 'react'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from 'app/services/util-services'
import supplierService from 'app/services/shipments/supplier/supplierServices'
import { DropDownBox } from 'devextreme-react'
import DataSource from 'devextreme/data/data_source'
import DataGrid, { FilterRow, Paging, Selection } from 'devextreme-react/data-grid'
import { useSnackbar } from 'notistack'
import { buildFilter } from 'app/utils/appHelpers'
import { useNavigate } from 'react-router-dom'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { useJumboTheme } from '@jumbo/hooks'
import { useMediaQuery } from '@mui/material'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'

const SuppliersPaymentGridFilter = ({ mutation, display, filterIconColor, onlinePayments }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))

    let initialValues = {
        startDate: DateUtils.getStartDate(10).toISOString().slice(0, 10),
        endDate: DateUtils.getEndDate().toISOString().slice(0, 10),
        onlinePayments: onlinePayments,
    }

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const [gridBoxValue, setGridBoxValue] = useState([''])
    const [isGridBoxOpened, setIsGridBoxOpened] = useState(false)

    const gridBoxDisplayExpr = useCallback((item) => {
        const balanceAmounts = item?.balanceAmount || 0
        const balanceAmount = balanceAmounts >= 0 ? balanceAmounts : `${Math.abs(balanceAmounts)}` // Avoid - minus value
        return item && `${item.supplierCode}, ${item.firstName + ' ' + item.lastName} , ${balanceAmount}`
    }, [])

    const [supplierStore, setSupplierStore] = useState(null)

    const supplierList = useCallback(
        async (filter) => {
            try {
                return await supplierService.getSupplierList(filter)
            } catch (error) {
                handleError(error)
            }
        },
        [supplierService]
    )

    const supplierById = useCallback(
        async (key) => {
            try {
                return await supplierService.getSupplierById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [supplierService]
    )

    const initializeSupplierStore = useCallback(() => {
        if (!supplierStore) {
            const newStore = new DataSource({
                store: new CustomStore({
                    loadMode: 'processed',
                    key: 'supplierId',
                    load: async function (loadOptions) {
                        let searchTerm = { skip: 0, take: 10 }
                        if (loadOptions?.filter) {
                            const filter = buildFilter(loadOptions.filter)
                            searchTerm = { ...searchTerm, ...filter }
                        }
                        return await supplierList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (utilServices.isNullOrUndefined(key) || key === '') {
                            return {}
                        }
                        return await supplierById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            })
            setSupplierStore(newStore)
        }
    }, [supplierStore])

    const syncDataGridSelection = useCallback((e) => {
        setGridBoxValue(e.value)
    }, [])

    const onGridBoxOpened = useCallback(
        (e) => {
            if (e.name === 'opened') {
                setIsGridBoxOpened(e.value)
                initializeSupplierStore()
            }
        },
        [initializeSupplierStore]
    )

    const dataGridOnSelectionChanged = useCallback((e) => {
        setGridBoxValue(e.selectedRowKeys)
        setIsGridBoxOpened(false)
    }, [])

    const gridColumns = [
        { dataField: 'supplierCode' },
        { dataField: 'firstName', caption: 'Name' },
        {
            dataField: 'balanceAmount',
            caption: 'Balance',
            format: (data) => {
                return formatCurrency(data)
            },
            allowFiltering: false,
            gridBoxDisplayExpr,
        },
    ]

    const dataGridRender = useCallback(
        () =>
            supplierStore && (
                <DataGrid
                    dataSource={supplierStore}
                    columns={gridColumns}
                    columnAutoWidth={true}
                    hoverStateEnabled={true}
                    showBorders={true}
                    selectedRowKeys={gridBoxValue}
                    onSelectionChanged={dataGridOnSelectionChanged}
                    height='100%'
                    remoteOperations={true}>
                    <Selection mode='single' />
                    <Paging enabled={false} pageSize={10} />
                    <FilterRow visible={true} applyFilter='auto' />
                </DataGrid>
            ),
        [gridBoxValue, dataGridOnSelectionChanged, supplierStore]
    )

    const onFormSubmit = async (startDate, endDate, onlinePayments) => {
        await mutation.mutate({
            startDate: startDate.toFilterStartDate(),
            endDate: endDate.toFilterEndDate(),
            onlinePayments: onlinePayments,
            supplierId: gridBoxValue[0] || null,
        })
    }

    const onFilterSubmit = async (data, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit(data.startDate, data.endDate, data.onlinePayments)
        setSubmitting(false)
    }

    const handleClear = () => {
        filterIconColor(false)
        setGridBoxValue([''])
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick
                        noWrapper
                        id='filterBody'
                        variant={'primary'}
                        sx={{ display: display, borderRadius: 0 }}>
                        <Divider />
                        <Div
                            className='grid-filter-parent'
                            style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='startDate'
                                        type='date'
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='endDate' type='date' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Typography>Supplier</Typography>
                                <Div sx={{ mt: 0.5 }}>
                                    <DropDownBox
                                        style={{ width: sm ? 310 : 400, marginLeft: sm ? '20px' : '' }}
                                        value={gridBoxValue}
                                        opened={isGridBoxOpened}
                                        valueExpr='supplierId'
                                        deferRendering={false}
                                        inputAttr={{ 'aria-label': 'Owner' }}
                                        displayExpr={gridBoxDisplayExpr}
                                        placeholder='Select a value...'
                                        dataSource={supplierStore}
                                        onValueChanged={syncDataGridSelection}
                                        onOptionChanged={onGridBoxOpened}
                                        contentRender={dataGridRender}
                                    />
                                </Div>
                            </Div>
                            <Div className='grid-filter-Button' sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default SuppliersPaymentGridFilter
