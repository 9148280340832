import React, { useState, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { SelectBox } from 'devextreme-react/select-box'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import AuditTypeService from '../Services/AuditTypeService'
import AuditSourceService from '../Services/AuditSourceService'
import companyAndBranchServices from 'app/services/settings/company/companyAndBranchServices'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from '../../../../services/util-services'
import { useSnackbar } from 'notistack'
import DataSource from 'devextreme/data/data_source'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'

const AuditLogGridFilter = ({ mutation, display, filterIconColor }) => {
    const navigate = useNavigate()
    const initialFilterFields = {
        companySelectedData: null,
        branchSelectedData: null,
    }

    let initialValues = {
        auditStartDate: DateUtils.getStartDate(10).toISOString().slice(0, 10),
        auditEndDate: DateUtils.getEndDate().toISOString().slice(0, 10),
        auditByStaffName: '',
    }

    const { enqueueSnackbar } = useSnackbar()
    const { authUser } = useJumboAuth()
    const [filterFields, setFilterFields] = useState({
        ...initialFilterFields,
    })

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }
    const onCompanyValueChanged = async (e) => {
        if (e) {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                companySelectedData: e.companyId,
            }))
        } else {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                companySelectedData: null,
                branchSelectedData: null,
            }))
        }
    }

    const onBranchValueChanged = (e) => {
        if (e) {
            setFilterFields({
                ...filterFields,
                branchSelectedData: e,
            })
        } else {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                branchSelectedData: null,
            }))
        }
    }

    const companyLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await companyAndBranchServices.getCompanyLookupList({ companyName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [companyAndBranchServices]
    )

    const companyLookupById = useCallback(
        async (key) => {
            try {
                return await companyAndBranchServices.getCompanyLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [companyAndBranchServices]
    )

    const companyLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'companyId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await companyLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return companyLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const branchLookupList = useCallback(
        async (searchTerm) => {
            try {
                if (utilServices.isNullOrUndefined(filterFields.companySelectedData)) return { totalCount: 0, data: [] }
                return await companyAndBranchServices.getBranchLookupList({
                    companyId: filterFields.companySelectedData,
                    branchName: searchTerm,
                })
            } catch (error) {
                handleError(error)
            }
        },
        [companyAndBranchServices, filterFields.companySelectedData]
    )

    const branchLookupById = useCallback(
        async (key) => {
            try {
                return await companyAndBranchServices.getBranchById(filterFields.companySelectedData, key)
            } catch (error) {
                handleError(error)
            }
        },
        [companyAndBranchServices]
    )

    const branchLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'branchId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await branchLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return branchLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        [filterFields.companySelectedData]
    )

    const [auditTypeCurSelection, setAuditTypeCurSelection] = useState('')
    const [auditSourceCurSelection, setAuditSourceCurSelection] = useState('')

    const onFormSubmit = async (auditStartDate, auditEndDate, auditByStaffName) => {
        await mutation.mutate({
            auditStartDate: auditStartDate.toFilterStartDate(),
            auditEndDate: auditEndDate.toFilterEndDate(),
            auditByStaffName: auditByStaffName,
            auditType: utilServices.isNullOrUndefined(auditTypeCurSelection) ? null : auditTypeCurSelection.id,
            auditSource: utilServices.isNullOrUndefined(auditSourceCurSelection) ? null : auditSourceCurSelection.id,
            companyId: utilServices.isNullOrUndefined(filterFields.companySelectedData)
                ? null
                : filterFields.companySelectedData,
            branchId: utilServices.isNullOrUndefined(filterFields.branchSelectedData)
                ? null
                : filterFields.branchSelectedData.branchId,
            sort: 'AuditDate',
            isDescending: true,
        })
    }

    const onFilterSubmit = async (data, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit(data.auditStartDate, data.auditEndDate, data.auditByStaffName)
        setSubmitting(false)
    }
    const handleClear = () => {
        filterIconColor(false)
        setAuditTypeCurSelection('')
        setAuditSourceCurSelection('')
        setFilterFields({ ...initialFilterFields })
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick
                        noWrapper
                        id='filterBody'
                        variant={'primary'}
                        sx={{ display: display, borderRadius: 0 }}>
                        <Divider />
                        <Div
                            className='grid-filter-parent'
                            style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='auditStartDate'
                                        type='date'
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='auditEndDate'
                                        type='date'
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Staff Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='auditByStaffName' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Audit Type</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={AuditTypeService.getAuditTypeInfo()}
                                        displayExpr={'auditType'}
                                        searchEnabled={true}
                                        onValueChange={setAuditTypeCurSelection}
                                        placeholder='All'
                                        showClearButton={true}
                                        value={auditTypeCurSelection}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Audit Source</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={AuditSourceService.getAuditSourceInfo()}
                                        displayExpr={'auditSource'}
                                        searchEnabled={true}
                                        onValueChange={setAuditSourceCurSelection}
                                        placeholder='All'
                                        showClearButton={true}
                                        value={auditSourceCurSelection}
                                    />
                                </Div>
                            </Div>
                            {(authUser?.isAdmin ?? true) && (
                                <Div sx={{ mr: 3, mt: 1.5 }}>
                                    <Div>
                                        <Typography>Company</Typography>
                                    </Div>
                                    <Div sx={{ mt: 0.5 }}>
                                        <SelectBox
                                            style={{ width: 220 }}
                                            dataSource={companyLookupsStore}
                                            value={filterFields.companySelectedData}
                                            displayExpr={'companyName'}
                                            searchEnabled={true}
                                            onValueChange={onCompanyValueChanged}
                                            placeholder='All'
                                            showClearButton={true}
                                        />
                                    </Div>
                                </Div>
                            )}
                            {(authUser?.isAdmin ?? true) && (
                                <Div sx={{ mr: 3, mt: 1.5 }}>
                                    <Div>
                                        <Typography>Branch</Typography>
                                    </Div>
                                    <Div sx={{ mt: 0.5 }}>
                                        <SelectBox
                                            style={{ width: 220 }}
                                            dataSource={branchLookupStore}
                                            value={filterFields.branchSelectedData}
                                            displayExpr={'branchName'}
                                            searchEnabled={true}
                                            onValueChange={onBranchValueChanged}
                                            placeholder='All'
                                            showClearButton={true}
                                        />
                                    </Div>
                                </Div>
                            )}
                            <Div className='grid-filter-Button' sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default AuditLogGridFilter
