import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { Card, CardContent } from '@mui/material'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Div from '@jumbo/shared/Div'
import TransferTimeLineCard from './transferTimeLineCard/components/TransferTimeLineCard'
import { TransferTimeLineData } from './transferTimeLineCard/components/data'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import ChangeTransferStatusForm from './transferTimeLineCard/components/ChangeTransferStatusForm'

const TransferRightInfoCard = ({ transfer }) => {
    const { showDialog, hideDialog } = useJumboDialog()
    const firstGridData = TransferTimeLineData.filter((item) => item.id >= 15 && item.id <= 30)
    const secondGridData = TransferTimeLineData.filter((item) => item.id >= 35 && item.id <= 60)

    const onChangeStatus = (transfer) => {
        showDialog({
            title: (
                <Div
                    sx={{
                        textAlign: 'center',
                        height: 48,
                        pt: 1.5,
                        bgcolor: 'success.main',
                        borderRadius: 1,
                        color: 'common.white',
                    }}>
                    Change Status
                </Div>
            ),
            content: <ChangeTransferStatusForm transfer={transfer} />,
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card
                    sx={{
                        height: { xs: 'auto', md: 371 },
                    }}>
                    <CardContent>
                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems='flex-start'>
                            <Grid item xs={12} md={6}>
                                <TransferTimeLineCard statusList={firstGridData} transfer={transfer} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TransferTimeLineCard statusList={secondGridData} transfer={transfer} />
                            </Grid>
                        </Stack>
                        <Grid
                            item
                            xs={12}
                            sx={{ marginTop: { md: '-10.5%', xs: 'auto' }, marginLeft: { md: '10%', xs: 'auto' } }}>
                            <Button
                                variant='outlined'
                                onClick={() => {
                                    onChangeStatus(transfer)
                                }}
                                sx={{
                                    margin: { xs: '0 auto', md: '0' },
                                    display: 'block',
                                }}>
                                Change Status
                            </Button>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default TransferRightInfoCard
