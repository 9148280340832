import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomerAuditLogList } from 'app/redux/store/features/customers'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import { LoadPanel } from 'devextreme-react/load-panel'
import Div from '@jumbo/shared/Div'
import CustomerAuditLogGridData from './components/CustomerAuditLogGridData'

const CustomerAuditLogs = ({ customerId }) => {
    const pageSize = 25
    const position = { of: '#dataGrid' }
    const [filter, setFilter] = useState({
        customerId: customerId,
        auditStartDate: DateUtils.getStartDate(10).toISOString(),
        auditEndDate: DateUtils.getEndDate().toISOString(),
        auditByStaffName: '',
    })

    const [currentPage, setCurrentPage] = useState(0)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const dispatch = useDispatch()
    const { customerAuditLogs, refreshing } = useSelector((state) => state.customers)

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getCustomerAuditLogList({ ...filter, skip: 0, take: pageSize }))
    }, [])

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getCustomerAuditLogList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getCustomerAuditLogList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getCustomerAuditLogList({ ...filter, skip: 0, take: pageSize }, true))
        setCurrentPage(0)
    }

    return (
        <React.Fragment>
            <Grid>
                <Div id='dataGrid'>
                    <Grid>
                        <CustomerAuditLogGridData
                            onRefresh={onRefresh}
                            onPageChange={onPageChange}
                            onColumnSort={onColumnSort}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            apiCallInProgress={apiCallInProgress}
                            setApiCallInProgress={setApiCallInProgress}
                        />
                    </Grid>
                    {customerAuditLogs.totalCount !== 0 && (
                        <LoadPanel shadingColor='rgba(0,0,0,0.4)' position={position} visible={refreshing} />
                    )}
                </Div>
            </Grid>
        </React.Fragment>
    )
}

export default CustomerAuditLogs
