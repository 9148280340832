import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import '../../../../../../constants/stylesheet.css'
import { useDispatch, useSelector } from 'react-redux'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import { LoadPanel } from 'devextreme-react/load-panel'
import Div from '@jumbo/shared/Div'
import useTheme from '@mui/system/useTheme'
import AddIcon from '@mui/icons-material/Add'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import DraftPaymentsData from './components/DraftPaymentsData'
import DraftPaymentsGridFilter from './components/DraftPaymentsGridFilter'
import { getPaymentList } from 'app/redux/store/features/customers'
import useJumboTheme from '../../../../../../../@jumbo/hooks/useJumboTheme'

const DraftPayments = () => {
    const navigate = useNavigate()
    const pageSize = 25
    const position = { of: '#dataGrid' }
    const dispatch = useDispatch()
    const [filter, setFilter] = useState({
        startDate: DateUtils.getStartDate(10).toISOString(),
        endDate: DateUtils.getEndDate().toISOString(),
        onlinePayments: true,
        customerId: null,
        sort: 'PaymentDate',
        isDescending: true,
    })

    const [currentPage, setCurrentPage] = useState(0)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { payments, refreshing } = useSelector((state) => state.customers)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getPaymentList({ ...filter, skip: 0, take: pageSize }))
    }, [dispatch])

    function init(initialValue) {
        return {
            gridFilterVisible: initialValue.gridFilterVisible,
            filterIconColor: initialValue.filterIconColor,
        }
    }

    function draftPaymentReducer(state, action) {
        switch (action.type) {
            case 'set-grid-filter':
                return {
                    ...state,
                    gridFilterVisible: action.payload,
                }
            case 'set-filter-icon-color':
                return {
                    ...state,
                    filterIconColor: action.payload,
                }
            default:
                return state
        }
    }

    const [PaymentList, setPaymentList] = React.useReducer(
        draftPaymentReducer,
        {
            gridFilterVisible: false,
            filterIconColor: false,
        },
        init
    )

    const applyFilter = useMutation({
        mutationFn: (currentfilter) => {
            dispatch(getPaymentList({ ...currentfilter, skip: 0, take: pageSize }, true))
            setFilter(currentfilter)
        },
    })

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getPaymentList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortPayment) => {
        const columnName = sortPayment[0].columnName.charAt(0).toUpperCase() + sortPayment[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            sort: columnName,
            isDescending: sortPayment[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getPaymentList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getPaymentList({ ...filter, skip: 0, take: pageSize }, true))
        setCurrentPage(0)
    }

    const setGridFilter = React.useCallback(
        (value) => {
            setPaymentList({ type: 'set-grid-filter', payload: value })
        },
        [setPaymentList]
    )

    const setFilterIconColor = React.useCallback(
        (value) => {
            setPaymentList({ type: 'set-filter-icon-color', payload: value })
        },
        [setPaymentList]
    )

    const onDraftPayment = () => {
        navigate('/store/customers/draftpayments/addpayment')
    }
    return (
        <React.Fragment>
            <Grid margin={1}>
                <JumboCardQuick
                    noWrapper
                    title={
                        <Typography color={'common.white'} variant={'h3'}>
                            <AutorenewRoundedIcon
                                onClick={onRefresh}
                                fontSize={'large'}
                                sx={{
                                    mb: -1.2,
                                    mr: 0.5,
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                            />
                            Payments
                        </Typography>
                    }
                    headerSx={{
                        height: 40,
                        bgcolor: 'secondary.main',
                        mt: 1,
                        pl: 1,
                    }}
                    action={
                        <Stack direction={'row'} spacing={4} sx={{ mt: -2 }}>
                            <AddIcon
                                size={'large'}
                                fontSize={'large'}
                                variant={'contained'}
                                color={'#e0e0e0'}
                                sx={{
                                    pt: 0,
                                    pb: 0,
                                    color: '#e0e0e0',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={onDraftPayment}
                            />
                            <FilterAltIcon
                                fontSize='large'
                                sx={{
                                    pt: 0,
                                    pb: 0,
                                    color:
                                        PaymentList.filterIconColor && !PaymentList.gridFilterVisible
                                            ? 'success.main'
                                            : 'common.white',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() => setGridFilter(!PaymentList.gridFilterVisible)}
                            />
                        </Stack>
                    }>
                    <Grid>
                        <DraftPaymentsGridFilter
                            mutation={applyFilter}
                            display={PaymentList.gridFilterVisible ? '' : 'none'}
                            filterIconColor={(data) => setFilterIconColor(data)}
                        />
                    </Grid>
                    <Div id='dataGrid'>
                        <Grid>
                            <DraftPaymentsData
                                onRefresh={onRefresh}
                                onPageChange={onPageChange}
                                onColumnSort={onColumnSort}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                apiCallInProgress={apiCallInProgress}
                                setApiCallInProgress={setApiCallInProgress}
                            />
                        </Grid>
                        {payments.totalCount !== 0 && (
                            <LoadPanel shadingColor='rgba(0,0,0,0.4)' position={position} visible={refreshing} />
                        )}
                    </Div>
                </JumboCardQuick>
            </Grid>
        </React.Fragment>
    )
}

export default DraftPayments
