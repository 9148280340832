import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { getPaymentById, selectPaymentById, UpdateCustomerPayment } from 'app/redux/store/features/customers.js'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import UpdateDraftPaymentForm from './UpdateDraftPaymentForm'
import utilServices from 'app/services/util-services'
import inventoryService from 'app/services/pages/store/inventory/inventoryServices'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'

const UpdateDraftPayment = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { customerPaymentId } = state
    const { payments, loading, error } = useSelector((state) => state.customers)
    const payment = selectPaymentById(payments, customerPaymentId)
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(payment.customerPaymentId))
            dispatch(getPaymentById({ customerPaymentId: customerPaymentId }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) {
            handleError(error)
        } else {
            enqueueSnackbar('Payment Updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/store/customers/draftpayments')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const ChartAccountLookupList = useCallback(async (searchTerm) => {
        try {
            return await inventoryService.getChartAccountLookupList({ accountName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ChartAccountLookupById = useCallback(async (key) => {
        try {
            return await inventoryService.getChartAccountLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ChartAccountStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'chartAccountId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await ChartAccountLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await ChartAccountLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const chartAccountEditorOptions = {
        dataSource: ChartAccountStore,
        displayExpr: 'accountName',
        valueExpr: 'chartAccountId',
        searchEnabled: true,
        showClearButton: true,
    }

    const updateDraftPayment = async () => {
        const totalAmount = (payment?.cashAmount || 0) + (payment?.othersAmount || 0) + (payment?.creditCardAmount || 0)
        if (totalAmount <= 0) {
            enqueueSnackbar('Please enter a valid amount greater than zero', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            return
        }
        setApiCallInProgress(true)
        dispatch(UpdateCustomerPayment({ ...payment, fkCustomerId: payment?.fkCustomerId }))
    }

    return (
        <React.Fragment>
            <UpdateDraftPaymentForm
                Payment={payment}
                updateDraftPayment={updateDraftPayment}
                chartAccountEditorOptions={chartAccountEditorOptions}
                customerPaymentId={customerPaymentId}
            />
        </React.Fragment>
    )
}

export default UpdateDraftPayment
