import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, ColCountByScreen, EmptyItem, GroupItem, Label } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import { getBranchById, selectItemById, updateBranch } from 'app/redux/store/features/branches'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import { useMediaQuery } from '@mui/material'

const UpdateBranchForm = (props) => {
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { companyId, branchId } = state
    const { loading: companyLoading, error: comapanyError, companyConfig } = useSelector((state) => state.companies)
    const { branches, loading: branchLoading, error: branchError } = useSelector((state) => state.branches)
    const branch = selectItemById(branches, branchId)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    useEffect(() => {
        if (!apiCallInProgress || branchLoading || companyLoading) return
        setApiCallInProgress(false)

        if (branchError) handleError(branchError)
        else if (comapanyError) handleError(comapanyError)
        else {
            enqueueSnackbar('Branch updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/settings/branches')
        }
    }, [branchLoading, companyLoading])

    useEffect(() => {
        if (utilServices.isNullOrUndefined(branch.branchId)) {
            dispatch(
                getBranchById({
                    companyId: companyId,
                    branchId: branchId,
                    reportXClientSecret: null,
                })
            )
        }
    }, [companyConfig.companyId])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate(`/settings/branches`),
    }

    const validationRules = {
        branchName: [{ type: 'required', message: 'Branch Name is required.' }],
        branchType: [{ type: 'required', message: 'Branch type is required.' }],
        licenseStartDate: [{ type: 'required', message: 'License Start Date is required.' }],
        licenseEndDate: [{ type: 'required', message: 'End Date is required.' }],
    }

    const onUpdateBranch = useCallback(async () => {
        setApiCallInProgress(true)
        dispatch(updateBranch({ ...branch }))
    }, [branch])

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onUpdateBranch)}>
                <Form
                    mode='form'
                    formData={branch}
                    showColonAfterLabel={false}
                    showValidationSummary={false}
                    labelLocation={labelLocation}>
                    <GroupItem cssClass='form-group-item' caption='Update Branch Details'>
                        <ColCountByScreen lg={13} md={1} sm={1} xs={1} />
                        <GroupItem />
                        <GroupItem />
                        <GroupItem />
                        <GroupItem cssClass='form-group-item' colSpan={6}>
                            <EmptyItem />
                            <SimpleItem
                                dataField='fkCompanyId'
                                editorType='dxSelectBox'
                                editorOptions={props.companyEditorOptions}>
                                <Label text='Company' />
                            </SimpleItem>
                            <SimpleItem dataField='branchName' validationRules={validationRules.storeName}>
                                <Label text='Branch Name' />
                            </SimpleItem>
                            <SimpleItem dataField='address' />
                            <SimpleItem dataField='phoneNo' />
                            <SimpleItem
                                dataField='branchType'
                                editorType='dxSelectBox'
                                editorOptions={props.storeEditorOptions}
                                validationRules={validationRules.branchType}
                            />
                            <GroupItem colCount={2}>
                                <SimpleItem dataField='transferAutoDispatch' editorType='dxCheckBox'>
                                    <Label text='Transfer Auto Dispatch' />
                                </SimpleItem>
                                <SimpleItem dataField='transferAutoReceive' editorType='dxCheckBox'>
                                    <Label text='Transfer Auto Receive' />
                                </SimpleItem>
                            </GroupItem>
                            <SimpleItem dataField='reportXClientId'>
                                <Label text='Client Id' />
                            </SimpleItem>
                            <SimpleItem dataField='reportXClientSecret'>
                                <Label text='Client Secret' />
                            </SimpleItem>
                            <SimpleItem dataField='clientVersion' editorOptions={{ readOnly: true }}>
                                <Label text='Client Version' />
                            </SimpleItem>
                            <SimpleItem
                                dataField='licenseStartDate'
                                editorType='dxDateBox'
                                validationRules={validationRules.licenseStartDate}
                                editorOptions={{
                                    displayFormat: 'shortDateShortTime',
                                    type: 'datetime',
                                    pickerType: 'calendar',
                                    useMaskBehavior: true,
                                }}>
                                <Label text='License Start Date' />
                            </SimpleItem>
                            <SimpleItem
                                dataField='licenseEndDate'
                                editorType='dxDateBox'
                                validationRules={validationRules.licenseEndDate}
                                editorOptions={{
                                    displayFormat: 'shortDateShortTime',
                                    type: 'datetime',
                                    pickerType: 'calendar',
                                    useMaskBehavior: true,
                                }}>
                                <Label text='License End Date' />
                            </SimpleItem>
                            <SimpleItem dataField='timezoneId' editorOptions={{ readOnly: true }}>
                                <Label text='Time Zone' />
                            </SimpleItem>
                        </GroupItem>
                    </GroupItem>
                    <EmptyItem />
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                        <ButtonItem
                            buttonOptions={{
                                disabled: branchLoading,
                                icon: branchLoading ? 'refresh' : 'check',
                                ...submitButtonOptions,
                            }}
                            horizontalAlignment='right'
                        />
                        <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                    </GroupItem>
                </Form>
            </form>
        </React.Fragment>
    )
}

export default UpdateBranchForm
