import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
    getSuppliersPaymentById,
    selectSupplierPaymentById,
    updateSupplierDraftPayment,
} from 'app/redux/store/features/suppliers'
import UpdateDraftSuppliersPaymentForm from './UpdateDraftSuppliersPaymentForm'
import { useDispatch, useSelector } from 'react-redux'
import utilServices from 'app/services/util-services'
import inventoryService from 'app/services/pages/store/inventory/inventoryServices'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const UpdateDraftSuppliersPayment = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { state } = useLocation()
    const { supplierPaymentId } = state
    const { supplierPayments, loading, error } = useSelector((state) => state.suppliers)
    const supplierPayment = selectSupplierPaymentById(supplierPayments, supplierPaymentId)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(supplierPayment.supplierPaymentId)) {
            dispatch(getSuppliersPaymentById({ supplierPaymentId: supplierPaymentId }))
        }
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return

        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Draft Supplier Payment data updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/store/suppliers/draftsupplierspayments')
        }
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const ChartAccountLookupList = useCallback(async (searchTerm) => {
        try {
            return await inventoryService.getChartAccountLookupList({ accountName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ChartAccountLookupById = useCallback(async (key) => {
        try {
            return await inventoryService.getChartAccountLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ChartAccountStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'chartAccountId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await ChartAccountLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await ChartAccountLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const chartAccountEditorOptions = {
        dataSource: ChartAccountStore,
        displayExpr: 'accountName',
        valueExpr: 'chartAccountId',
        searchEnabled: true,
        showClearButton: true,
    }

    const onUpdateDraftSuppliersPayment = async () => {
        const cashAmount = parseFloat(formatCurrency(supplierPayment.cashAmount || 0))
        const othersAmount = parseFloat(formatCurrency(supplierPayment.othersAmount || 0))
        const creditCardAmount = parseFloat(formatCurrency(supplierPayment.creditCardAmount || 0))
        const totalAmount = cashAmount + othersAmount + creditCardAmount

        if (totalAmount <= 0) {
            enqueueSnackbar('Please enter a valid amount greater than zero', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            return
        }
        setApiCallInProgress(true)
        dispatch(updateSupplierDraftPayment({ ...supplierPayment, fkSupplierId: supplierPayment?.fkSupplierId }))
    }

    return (
        <React.Fragment>
            <UpdateDraftSuppliersPaymentForm
                supplierPaymentData={supplierPayment}
                chartAccountEditorOptions={chartAccountEditorOptions}
                supplierPaymentId={supplierPaymentId}
                updateDraftSuppliersPayment={onUpdateDraftSuppliersPayment}
            />
        </React.Fragment>
    )
}

export default UpdateDraftSuppliersPayment
