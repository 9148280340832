import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { updateTransfer } from '../../../../../../redux/store/features/transfers'
import { useSnackbar } from 'notistack'
import { useMediaQuery } from '@mui/material'

const UpdateTransferForm = (props) => {
    const navigate = useNavigate()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.transfers)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    useEffect(() => {
        if (!apiCallInProgress || loading) return

        setApiCallInProgress(false)

        if (error) props.handleError(error)
        else {
            enqueueSnackbar('Transfer data updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/company/transfers/inventories')
        }
    }, [loading])

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/company/transfers/inventories'),
    }

    const validationRules = {
        transferRequiredDate: [{ type: 'required', message: 'Transfer Date is required.' }],
    }

    const shippingCostEditorOptions = {
        format: (data) => formatCurrency(data), // Pass the formatCurrency function directly
    }

    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })

    const onUpdateTransfer = useCallback(async () => {
        setApiCallInProgress(true)
        dispatch(updateTransfer({ ...props.transfer }))
    }, [props.transfer])

    return (
        <form onSubmit={handleSubmit(onUpdateTransfer)}>
            <Form mode='form' formData={props.transfer} showColonAfterLabel={false} labelLocation={labelLocation}>
                <GroupItem>
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                        <GroupItem>
                            <GroupItem caption='Transfer Details' cssClass='form-group-item'>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <GroupItem>
                                        <SimpleItem
                                            dataField='transferNo'
                                            editorOptions={{
                                                placeholder: 'Auto generated on save',
                                                readOnly: true,
                                            }}>
                                            <Label text='Transfer Number' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='fkSenderBranchId'
                                            editorType='dxSelectBox'
                                            editorOptions={props.senderBranchEditorOptions}>
                                            <Label text='Sender Branch' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='fkReceivingBranchId'
                                            editorType='dxSelectBox'
                                            editorOptions={props.receivingBranchEditorOptions}>
                                            <Label text='Receiving Branch' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='fkTransferRequestId'
                                            editorType='dxSelectBox'
                                            editorOptions={props.transferRequestEditorOptions}>
                                            <Label text='Transfer Request' />
                                        </SimpleItem>

                                        <SimpleItem dataField='barcode' />
                                        <SimpleItem
                                            dataField='transferRequiredDate'
                                            editorType='dxDateBox'
                                            editorOptions={{
                                                displayFormat: 'shortDateShortTime',
                                                type: 'datetime',
                                                pickerType: 'calendar',
                                                useMaskBehavior: true,
                                            }}
                                            validationRules={validationRules.transferRequiredDate}>
                                            <Label text='Transfer Date' />
                                        </SimpleItem>
                                        <SimpleItem dataField='refNumber'>
                                            <Label text='Reference Number' />
                                        </SimpleItem>
                                        <SimpleItem dataField='comments' editorType='dxTextArea' />
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <GroupItem>
                            <GroupItem caption='Shipping Details' cssClass='form-group-item '>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <SimpleItem dataField='hasShippingCost' editorType='dxCheckBox'>
                                        <Label text='Shipping is required' />
                                    </SimpleItem>
                                    <SimpleItem
                                        editorType='dxNumberBox'
                                        dataField='shippingCost'
                                        editorOptions={shippingCostEditorOptions}>
                                        <Label text='Shipping Cost' />
                                    </SimpleItem>
                                    <SimpleItem dataField='shippingComments' editorType='dxTextArea'>
                                        <Label text='Shipping Comments' />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem caption='Configuration' cssClass='form-group-item '>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <GroupItem>
                                        <ColCountByScreen lg={2} md={1} sm={1} xs={1} />
                                        <SimpleItem
                                            dataField='transferAutoDispatch'
                                            editorType='dxCheckBox'
                                            editorOptions={{
                                                readOnly: true,
                                            }}>
                                            <Label text='Transfer Auto Dispatch' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='overwritePurchasePriceOnReceivingSide'
                                            editorType='dxCheckBox'
                                            editorOptions={{
                                                readOnly: true,
                                            }}>
                                            <Label text='Update Purchase Price When Receiving' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='transferAutoReceive'
                                            editorType='dxCheckBox'
                                            editorOptions={{
                                                readOnly: true,
                                            }}>
                                            <Label text='Transfer Auto Receive' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='overwriteSalePriceOnReceivingSide'
                                            editorType='dxCheckBox'
                                            editorOptions={{
                                                readOnly: true,
                                            }}>
                                            <Label text='Update Sale Price When Receiving' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                    </GroupItem>
                </GroupItem>
                <EmptyItem />
                <GroupItem>
                    <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                    <ButtonItem
                        buttonOptions={{
                            disabled: loading,
                            icon: loading ? 'refresh' : 'check',
                            ...submitButtonOptions,
                        }}
                        horizontalAlignment='right'
                    />
                    <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                </GroupItem>
            </Form>
        </form>
    )
}

export default UpdateTransferForm
