import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { IconButton, Paper } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useJumboTheme } from '@jumbo/hooks'
import VisibilityIcon from '@mui/icons-material/Visibility'

const SuppliersPaymentDataGrid = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { supplierPayments, loading, error } = useSelector((state) => state.suppliers)
    const booleanColumns = ['isTaxExempted']
    const [sorting, setSorting] = useState([{ columnName: 'paymentNo', direction: 'asc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton>
                    <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />
    const BooleanFormatter = ({ value }) => <span>{formatBooleanValue(value)}</span>
    const formatBooleanValue = (value) => (value ? 'Yes' : 'No')
    const BooleanTypeProvider = (props) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />

    const columns = [
        { name: 'paymentNo', title: 'Payment No' },
        { name: 'supplierFirstName', title: 'First Name' },
        { name: 'supplierLastName', title: 'Last Name' },
        { name: 'entryTypeName', title: 'Entry Type' },
        { name: 'paymentDate', title: 'Payment Date', getCellValue: (row) => formatDateTime(row.paymentDate) },
        { name: 'cashPayment', title: 'Cash Payment', getCellValue: (row) => formatCurrency(row.cashAmount) },
        {
            name: 'creditCardPayment',
            title: 'Credit Payment',
            getCellValue: (row) => formatCurrency(row.creditCardAmount),
        },
        { name: 'otherPayment', getCellValue: (row) => formatCurrency(row.othersAmount), title: 'Other Payment' },
        { name: 'totalPaid', getCellValue: (row) => formatCurrency(row.totalPaid), title: 'Total Paid' },
        { name: 'transcationBy', title: 'Txn By' },
        { name: 'orderNo', title: 'Order No' },
        { name: 'paymentStatus', title: 'Status' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            {
                columnName: 'paymentNo',
                width: min ? '120px' : '8%',
                minWidth: '120px',
                wordWrapEnabled: true,
            },
            { columnName: 'supplierFirstName', wordWrapEnabled: true, width: min ? '100px' : '8%', minWidth: '100px' },
            { columnName: 'supplierLastName', wordWrapEnabled: true, width: min ? '100px' : '8%', minWidth: '100px' },
            { columnName: 'entryTypeName', wordWrapEnabled: true, width: min ? '100px' : '7%', minWidth: '100px' },
            { columnName: 'paymentDate', wordWrapEnabled: true, width: min ? '110px' : '12%', minWidth: '110px' },
            {
                columnName: 'cashPayment',
                align: 'right',
                wordWrapEnabled: true,
                width: min ? '110px' : '7%',
                minWidth: '110px',
            },
            {
                columnName: 'creditCardPayment',
                align: 'right',
                wordWrapEnabled: true,
                width: min ? '110px' : '7%',
                minWidth: '110px',
            },
            {
                columnName: 'otherPayment',
                align: 'right',
                wordWrapEnabled: true,
                width: min ? '110px' : '7%',
                minWidth: '110px',
            },
            {
                columnName: 'totalPaid',
                align: 'right',
                wordWrapEnabled: true,
                width: min ? '100px' : '8%',
                minWidth: '100px',
            },
            {
                columnName: 'transcationBy',
                align: 'left',
                wordWrapEnabled: true,
                width: min ? '100px' : '8%',
                minWidth: '100px',
            },
            {
                columnName: 'orderNo',
                align: 'left',
                wordWrapEnabled: true,
                width: min ? '90px' : '7%',
                minWidth: '90px',
            },
            {
                columnName: 'paymentStatus',
                align: 'left',
                wordWrapEnabled: true,
                width: min ? '90px' : '7%',
                minWidth: '90px',
            },
            {
                columnName: 'Action',
                align: 'center',
                wordWrapEnabled: true,
                width: min ? '90px' : '6%',
                minWidth: '90px',
            },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={supplierPayments.data} columns={columns}>
                <BooleanTypeProvider for={booleanColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={supplierPayments.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default SuppliersPaymentDataGrid
