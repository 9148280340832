import React, { useCallback, useState } from 'react'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import DataSource from 'devextreme/data/data_source'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from 'app/services/util-services'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { DropDownBox } from 'devextreme-react'
import { useJumboTheme } from '@jumbo/hooks'
import { useMediaQuery } from '@mui/material'
import DataGrid, { FilterRow, Paging, Selection } from 'devextreme-react/data-grid'
import { buildFilter } from 'app/utils/appHelpers'
import customerCategoryService from 'app/services/pages/store/customers/customerCategoryServices'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const PaymentsGridFilter = ({ mutation, display, filterIconColor }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const [gridBoxValue, setGridBoxValue] = useState([''])
    const [isGridBoxOpened, setIsGridBoxOpened] = useState(false)
    const [customerLookupStore, setCustomerLookupStore] = useState(null)
    const [fkCustomerId, setFkCustomerId] = useState(null)

    const gridBoxDisplayExpr = useCallback((item) => {
        return item && `${item.firstName + ' ' + item.lastName}, ${item.mobileNo} , ${item.balanceAmount}`
    }, [])

    const customerLookupList = useCallback(async (filter) => {
        try {
            return await customerCategoryService.getCustomerList(filter)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const customerLookupById = useCallback(async (key) => {
        try {
            return await customerCategoryService.getCustomerById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const initializeCustomerStore = useCallback(() => {
        if (!customerLookupStore) {
            const newStore = new DataSource({
                store: new CustomStore({
                    loadMode: 'processed',
                    key: 'customerId',
                    load: async function (loadOptions) {
                        let searchTerm = { skip: 0, take: 10 }
                        if (loadOptions?.filter) {
                            const filter = buildFilter(loadOptions.filter)
                            searchTerm = { ...searchTerm, ...filter }
                        }
                        return await customerLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (utilServices.isNullOrUndefined(key) || key === '') {
                            return {}
                        }
                        return await customerLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            })
            setCustomerLookupStore(newStore)
        }
    }, [customerLookupStore])

    const syncDataGridSelection = useCallback((e) => {
        // Ensure e.value is not null or undefined and is an array
        const value = Array.isArray(e.value) ? e.value : []
        setGridBoxValue(value)

        if (value.length > 0) {
            setFkCustomerId(value[0])
        } else {
            setFkCustomerId(null)
        }
    }, [])

    const onGridBoxOpened = useCallback(
        (e) => {
            if (e.name === 'opened') {
                setIsGridBoxOpened(e.value)
                initializeCustomerStore()
            }
        },
        [initializeCustomerStore]
    )

    const dataGridOnSelectionChanged = useCallback((e) => {
        setGridBoxValue(e.selectedRowKeys)
        setIsGridBoxOpened(false)
    }, [])

    const gridColumns = [
        { dataField: 'firstName', caption: 'Name' },
        { dataField: 'mobileNo', width: 150 },
        {
            dataField: 'balanceAmount',
            caption: 'Balance',
            format: (data) => {
                return formatCurrency(data)
            },
            width: 80,
            allowFiltering: false,
        },
    ]

    const dataGridRender = useCallback(
        () =>
            customerLookupStore && (
                <DataGrid
                    dataSource={customerLookupStore}
                    columns={gridColumns}
                    columnAutoWidth={true}
                    hoverStateEnabled={true}
                    showBorders={true}
                    selectedRowKeys={gridBoxValue}
                    onSelectionChanged={dataGridOnSelectionChanged}
                    height='100%'
                    remoteOperations={true}>
                    <Selection mode='single' />
                    <Paging enabled={false} pageSize={10} />
                    <FilterRow visible={true} applyFilter='auto' />
                </DataGrid>
            ),
        [gridBoxValue, dataGridOnSelectionChanged, customerLookupStore]
    )

    let initialValues = {
        startDate: DateUtils.getStartDate(10).toISOString().slice(0, 10),
        endDate: DateUtils.getEndDate().toISOString().slice(0, 10),
    }

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const onFormSubmit = async (startDate, endDate) => {
        await mutation.mutate({
            startDate: startDate.toFilterStartDate(),
            endDate: endDate.toFilterEndDate(),
            onlinePayments: false,
            customerId: gridBoxValue[0] || null,
            sort: 'PaymentDate',
            isDescending: true,
        })
    }

    const onFilterSubmit = async (data, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit(data.startDate, data.endDate)
        setSubmitting(false)
    }

    const handleClear = () => {
        filterIconColor(false)
        setGridBoxValue([''])
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick
                        noWrapper
                        id='filterBody'
                        variant='primary'
                        sx={{ display: display, borderRadius: 0 }}>
                        <Divider />
                        <Div
                            className='grid-filter-parent'
                            style={{
                                display: 'flex',
                                margin: '0% 1.5% 1.5% 1.5%',
                                flexWrap: 'wrap',
                            }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='startDate'
                                        type='date'
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='endDate' type='date' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Typography>Customer</Typography>
                                <Div sx={{ mt: 0.5 }}>
                                    <DropDownBox
                                        style={{ width: sm ? 310 : 400, marginLeft: sm ? '20px' : '' }}
                                        value={gridBoxValue}
                                        opened={isGridBoxOpened}
                                        valueExpr='customerId'
                                        deferRendering={false}
                                        inputAttr={{ 'aria-label': 'Owner' }}
                                        displayExpr={gridBoxDisplayExpr}
                                        placeholder='Select a value...'
                                        showClearButton={true}
                                        dataSource={customerLookupStore}
                                        onValueChanged={syncDataGridSelection}
                                        onOptionChanged={onGridBoxOpened}
                                        contentRender={dataGridRender}
                                    />
                                </Div>
                            </Div>
                            <Div className='grid-filter-Button' sx={{ mt: 5 }}>
                                <Stack direction='row' spacing={1}>
                                    <LoadingButton
                                        size='small'
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant='contained'
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton size='small' type='reset' onClick={handleClear} variant='contained'>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default PaymentsGridFilter
