import React, { useEffect, useState, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import useJumboTheme from '../../../../../@jumbo/hooks/useJumboTheme'

const AuditLogGridData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { auditLog, loading, error } = useSelector((state) => state.auditLog)
    const dateColumns = ['auditDate', 'auditEndDate']
    const [sorting, setSorting] = useState([{ columnName: 'auditDate', direction: 'desc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = ({ row }) => {
        navigate(`/company/auditlogs/details/${row.auditLogId}`, { state: { auditLogId: row.auditLogId } })
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onNavigatingToDetail({ row })}>
                    <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const columns = [
        { name: 'auditDate', title: 'Audit Date' },
        { name: 'referenceDate', title: 'Refrence Date' },
        { name: 'branchName', title: 'Branch' },
        { name: 'auditType', title: 'Audit Type' },
        { name: 'auditSource', title: 'Audit Source' },
        { name: 'reference', title: 'Refrence' },
        { name: 'message', title: 'Message' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'auditDate', width: min ? '130px' : '13%', minWidth: '130px', wordWrapEnabled: true },
            { columnName: 'referenceDate', width: min ? '130px' : '13%', minWidth: '130px', wordWrapEnabled: true },
            { columnName: 'branchName', width: min ? '100px' : '10%', minWidth: '100px', wordWrapEnabled: true },
            { columnName: 'auditType', width: min ? '80px' : '8%', minWidth: '80px', wordWrapEnabled: true },
            { columnName: 'auditSource', width: min ? '90px' : '9%', minWidth: '90px', wordWrapEnabled: true },
            { columnName: 'reference', width: min ? '110px' : '10%', minWidth: '110px', wordWrapEnabled: true },
            {
                columnName: 'message',
                title: 'Message',
                width: min ? '280px' : '28%',
                minWidth: '280px',
                wordWrapEnabled: true,
            },
            { columnName: 'Action', width: min ? '90px' : '9%', minWidth: '90px', align: 'center' },
        ],
        [min]
    )
    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [
        { columnName: 'Action', sortingEnabled: false },
        { columnName: 'auditType', sortingEnabled: false },
        { columnName: 'auditSource', sortingEnabled: false },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={auditLog.data} columns={columns}>
                <DateTypeProvider for={dateColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={auditLog.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default AuditLogGridData
