import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { AddUpdateOrderItems } from 'app/redux/store/features/orders'
import OrderItemAddHeader from './components/OrderItemAddHeader'
import DataSource from 'devextreme/data/data_source'
import DataGrid, { Column, Editing, Paging, Scrolling } from 'devextreme-react/data-grid'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { defaultOrderItem } from './components/defaultOrderItem'
import uuid from 'draft-js/lib/uuid'
import LocalStore from 'devextreme/data/local_store'
import { useJumboTheme } from '@jumbo/hooks'
import { useMediaQuery } from '@mui/material'
import InventoryBasePriceService from './components/InventoryBasePrice'

const OrderItemGrid = (props) => {
    const dispatch = useDispatch()
    const gridRef = useRef(null)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        gridRef.current.instance.refresh(true)
        gridRef.current.instance.cancelEditData()
    }, [props.inventoryItems])

    const onClickSave = useCallback(async () => {
        await gridRef.current.instance.saveEditData()
    }, [])

    const onClickAdd = useCallback(async (data) => {
        let newInventory = {
            ...defaultOrderItem,
            orderItemId: uuid(),
            wholeSaleUntiPrice: data.wholeSaleUntiPrice || defaultOrderItem.wholeSaleUntiPrice,
            wholeSaleUntiPricePercentage:
                data.wholeSaleUntiPricePercentage || defaultOrderItem.wholeSaleUntiPricePercentage,
            fkStockShipmentId: props.orderId,
            invName: data.inventory.inventoryName,
            invNo: data.inventory.inventoryNo,
            inventoryBasePrice: data.inventoryBasePriceData,
            fkInventoryId: data.inventory.inventoryId,
            qtyOrdered: data.qtyOrdered,
            orderPrice: data.orderPrice || defaultOrderItem.orderPrice,
            salePrice: data.salePrice || defaultOrderItem.salePrice,
            discountRate: data.discountRate || defaultOrderItem.discountRate,
            taxRate: data.purchaseTaxRate || defaultOrderItem.taxRate,
            marketPrice: data.marketPrice || defaultOrderItem.marketPrice,
            orderPricePercentage: data.orderPricePercentage || defaultOrderItem.orderPricePercentage,
            salePricePercentage: data.salePricePercentage || defaultOrderItem.salePricePercentage,
            profitMargin: data.inventory.profitMargin || defaultOrderItem.profitMargin,
            fkStockStorageId: data.inventoryStock,
            expiryDate: data.expiryDate,
            additionalTax: data.additionalTax,
            qtyZeroPrice: data.qtyZeroPrice,
        }
        gridRef.current.instance.addRow()
        Object.keys(newInventory).forEach((key) => {
            gridRef.current.instance.cellValue(0, key, newInventory[key]) // Set the new values
        })
        gridRef.current.instance.cellValue(0, 'orderItemId', newInventory.orderItemId)
        gridRef.current.instance.cellValue(0, 'fkStockShipmentId', newInventory.fkStockShipmentId)
        gridRef.current.instance.cellValue(0, 'invName', newInventory.invName)
        gridRef.current.instance.cellValue(0, 'invNo', newInventory.invNo)
        gridRef.current.instance.cellValue(0, 'inventoryBasePrice', newInventory.inventoryBasePrice)
        gridRef.current.instance.cellValue(0, 'fkInventoryId', newInventory.fkInventoryId)
        gridRef.current.instance.cellValue(0, 'qtyOrdered', newInventory.qtyOrdered)
        gridRef.current.instance.cellValue(0, 'orderPrice', newInventory.orderPrice)
        gridRef.current.instance.cellValue(0, 'salePrice', newInventory.salePrice)
        gridRef.current.instance.cellValue(0, 'discountRate', newInventory.discountRate)
        gridRef.current.instance.cellValue(0, 'taxRate', newInventory.taxRate)
        gridRef.current.instance.cellValue(0, 'marketPrice', newInventory.marketPrice)
        gridRef.current.instance.cellValue(0, 'orderPricePercentage', newInventory.orderPricePercentage)
        gridRef.current.instance.cellValue(0, 'salePricePercentage', newInventory.salePricePercentage)
        gridRef.current.instance.cellValue(0, 'profitMargin', newInventory.profitMargin)
        gridRef.current.instance.cellValue(0, 'fkStockStorageId', newInventory.fkStockStorageId)
        gridRef.current.instance.cellValue(0, 'expiryDate', newInventory.expiryDate)
        gridRef.current.instance.cellValue(0, 'wholeSaleUntiPrice', newInventory.wholeSaleUntiPrice)
        gridRef.current.instance.cellValue(0, 'wholeSaleUntiPricePercentage', newInventory.wholeSaleUntiPricePercentage)
        gridRef.current.instance.cellValue(0, 'additionalTax', newInventory.additionalTax)
        gridRef.current.instance.cellValue(0, 'qtyZeroPrice', newInventory.qtyZeroPrice)
    }, [])

    const OrderItemStore = useMemo(() => {
        return new LocalStore({
            key: 'orderItemId',
            data: props.inventoryItems?.data ?? [],
            name: 'transferItems',
        })
    }, [props.inventoryItems])

    const OrderItemDataSource = useMemo(() => {
        return new DataSource({
            store: OrderItemStore,
            reshapeOnPush: true,
        })
    }, [])

    async function sendBatchRequest(changes) {
        let order = {
            orderId: props.orderId,
            inventoryItems: [],
            removeItems: [],
        }
        let gridRows = await OrderItemStore.load()
        changes.forEach((c) => {
            if (c.type === 'remove') {
                order.removeItems.push({ orderItemId: c.key })
                return
            }
            let row = c.type === 'update' ? gridRows.find((entry) => entry.orderItemId === c.key) : {}
            order.inventoryItems.push({
                orderItemId: c?.key?.replace('_DX_KEY_', '') ?? row?.orderItemId,
                fkStockShipmentId: props.orderId,
                invName: c?.data?.invName ?? row?.invName,
                invNo: c?.data?.invNo ?? row?.invNo,
                inventoryBasePrice: c?.data?.inventoryBasePrice ?? row?.inventoryBasePrice,
                fkInventoryId: c?.data?.fkInventoryId ?? row?.fkInventoryId,
                qtyOrdered: c?.data?.qtyOrdered ?? row?.qtyOrdered,
                orderPrice: c?.data?.orderPrice ?? row?.orderPrice,
                salePrice: c?.data?.salePrice ?? row?.salePrice,
                discountRate: c?.data?.discountRate ?? row?.discountRate,
                taxRate: c?.data?.taxRate ?? row?.taxRate,
                marketPrice: c?.data?.marketPrice ?? row?.marketPrice,
                orderPricePercentage: c?.data?.orderPricePercentage ?? row?.orderPricePercentage ?? null,
                salePricePercentage: c?.data?.salePricePercentage ?? row?.salePricePercentage ?? null,
                profitMargin: c?.data?.profitMargin ?? row?.profitMargin,
                fkStockStorageId: c?.data?.fkStockStorageId ?? row?.fkStockStorageId ?? null,
                qtyZeroPrice: c?.data?.qtyZeroPrice ?? row?.qtyZeroPrice,
                wholeSaleUntiPrice: c?.data?.wholeSaleUntiPrice ?? row?.wholeSaleUntiPrice,
                wholeSaleUntiPricePercentage:
                    c?.data?.wholeSaleUntiPricePercentage ?? row?.wholeSaleUntiPricePercentage ?? null,
                additionalTax: c?.data?.additionalTax ?? row?.additionalTax,
                expiryDate: c?.data?.expiryDate ?? row?.expiryDate ?? null,
            })
        })
        dispatch(AddUpdateOrderItems(order))
    }

    async function processBatchRequest(changes, component) {
        await sendBatchRequest(changes)
    }

    const onSaving = (e) => {
        e.cancel = true
        if (e.changes.length) {
            e.promise = processBatchRequest(e.changes, e.component)
        }
    }

    return (
        <Grid sx={{ marginTop: '-24px', marginBottom: '-24px' }}>
            <Grid sx={{ marginBottom: sm ? '24px' : '' }}>
                <OrderItemAddHeader
                    onClickAdd={onClickAdd}
                    onClickSave={onClickSave}
                    isDraftStatus={props.orderStatus}
                />
            </Grid>
            <Grid>
                <DataGrid
                    ref={gridRef}
                    height={sm ? 'auto' : '780px'}
                    className='OrderItemdDataGrid'
                    dataSource={OrderItemDataSource}
                    remoteOperations={true}
                    onSaving={onSaving}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    allowColumnResizing={true}
                    columnResizingMode='nextColumn'>
                    <Scrolling columnRenderingMode='virtual' />
                    <Paging enabled={false} />
                    <Editing
                        mode='batch'
                        allowUpdating={true}
                        allowDeleting={props.orderStatus == 'Approved' ? false : true}
                        useIcons={true}
                        startEditAction={'dblClick'}
                    />
                    <Column dataField='storageName' caption='Stock Location' visible={false} allowEditing={false} />
                    <Column dataField='expiryDate' caption='Expiry Date' visible={false} allowEditing={false} />
                    <Column dataField='additionalTax' caption='Additional Tax' visible={false} allowEditing={false} />
                    <Column
                        dataField='wholeSaleUntiPricePercentage'
                        caption='Wholesale Price %'
                        visible={false}
                        allowEditing={false}
                    />
                    <Column
                        dataField='wholeSaleUntiPrice'
                        caption='Wholesale Price'
                        visible={false}
                        allowEditing={false}
                    />
                    <Column dataField='qtyZeroPrice' caption='Bonus Quantity' visible={false} allowEditing={false} />
                    <Column
                        dataField='salePricePercentage'
                        caption='Sale Price %'
                        visible={false}
                        allowEditing={false}
                    />
                    <Column dataField='fkInventoryId' visible={false} allowEditing={false} />
                    <Column
                        dataField='invNo'
                        caption='Inventory No'
                        width={min ? '100px' : '10%'}
                        minWidth='100px'
                        allowEditing={false}
                    />
                    <Column
                        dataField='invName'
                        caption='Product Name'
                        width={min ? '130px' : '18%'}
                        minWidth='130px'
                        allowEditing={false}
                    />
                    <Column
                        dataField='qtyOrdered'
                        caption='Qty Received'
                        dataType='number'
                        alignment='center'
                        width={min ? '100px' : '8%'}
                        minWidth='100px'
                        allowEditing={props.orderStatus === 'Draft' ? true : false}
                    />
                    <Column
                        dataField='orderPrice'
                        caption='Order Price'
                        dataType='number'
                        alignment='right'
                        width={min ? '90px' : '8%'}
                        minWidth='90px'
                        allowEditing={props.orderStatus === 'Draft' ? true : false}
                        format={(data) => formatCurrency(data)}
                    />
                    <Column
                        dataField='inventoryBasePrice'
                        caption='Base Price '
                        width={min ? '90px' : '8%'}
                        minWidth='90px'
                        alignment='center'
                        calculateCellValue={(rowData) =>
                            InventoryBasePriceService.getBasePriceServiceById(rowData.inventoryBasePrice)
                        }
                        allowEditing={false}
                    />
                    <Column
                        dataField='salePrice'
                        caption='Sale Price'
                        dataType='number'
                        width={min ? '90px' : '8%'}
                        minWidth='90px'
                        format={(data) => formatCurrency(data)}
                        alignment='right'
                        allowEditing={props.orderStatus === 'Draft' ? true : false}
                    />
                    <Column
                        dataField='discountRate'
                        caption='Discount'
                        dataType='number'
                        format={(data) => formatCurrency(data)}
                        alignment='right'
                        width={min ? '90px' : '8%'}
                        minWidth='90px'
                        allowEditing={props.orderStatus === 'Draft' ? true : false}
                    />
                    <Column
                        dataField='taxRate'
                        caption='Tax'
                        dataType='number'
                        format={(data) => formatCurrency(data)}
                        alignment='right'
                        width={min ? '90px' : '8%'}
                        minWidth='90px'
                        allowEditing={props.orderStatus === 'Draft' ? true : false}
                    />
                    <Column
                        dataField='marketPrice'
                        caption='Market Price'
                        dataType='number'
                        format={(data) => formatCurrency(data)}
                        alignment='right'
                        width={min ? '90px' : '8%'}
                        minWidth='90px'
                        allowEditing={props.orderStatus === 'Draft' ? true : false}
                    />
                    <Column
                        dataField='orderPricePercentage'
                        caption='Order Price %'
                        dataType='number'
                        format={(data) => formatCurrency(data)}
                        alignment='center'
                        width={min ? '110px' : '8%'}
                        minWidth='110px'
                        allowEditing={props.orderStatus === 'Draft' ? true : false}
                    />
                    <Column
                        dataField='profitMargin'
                        caption='Profit Margin'
                        width={min ? '100px' : '8%'}
                        minWidth='100px'
                        alignment='right'
                        allowEditing={false}
                    />
                    <Column
                        dataField='totalAmount'
                        caption='Total'
                        width={min ? '100px' : '8%'}
                        minWidth='100px'
                        format={(data) => formatCurrency(data)}
                        alignment='right'
                        allowEditing={false}
                    />
                </DataGrid>
            </Grid>
        </Grid>
    )
}

export default OrderItemGrid
