import React, { useEffect, useState, useMemo, useCallback } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper } from '@mui/material'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDraftSupplierPayment, getSuppliersPaymentById } from 'app/redux/store/features/suppliers.js'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useJumboTheme } from '@jumbo/hooks'
import Div from '@jumbo/shared/Div'
import DraftSupplierPaymentApprovedStatus from './DraftSupplierPaymentApprovedStatus'

const SuppliersPaymentDataGrid = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const { supplierPayments, loading, error } = useSelector((state) => state.suppliers)
    const booleanColumns = ['isTaxExempted']
    const [sorting, setSorting] = useState([{ columnName: 'paymentNo', direction: 'asc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToEdit = useCallback(
        (id) => {
            dispatch(getSuppliersPaymentById({ supplierPaymentId: id }))
            navigate(`/store/suppliers/draftsupplierspayments/edit/${id}`, { state: { supplierPaymentId: id } })
        },
        [dispatch, navigate]
    )

    const onNavigatingToChangeStatus = (supplierPaymentId) => {
        showDialog({
            title: (
                <Div
                    sx={{
                        textAlign: 'center',
                        height: 48,
                        pt: 1.5,
                        bgcolor: 'success.main',
                        borderRadius: 1,
                        color: 'common.white',
                    }}>
                    Process Payment
                </Div>
            ),
            content: <DraftSupplierPaymentApprovedStatus supplierPaymentId={supplierPaymentId} />,
        })
    }
    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToEdit(tableRow.row.supplierPaymentId)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                {row.paymentStatus === 'Draft' && (
                    <>
                        <IconButton onClick={() => onNavigatingToChangeStatus(row.supplierPaymentId)}>
                            <CheckCircleOutlineIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                        </IconButton>
                        <IconButton onClick={() => onNavigatingToEdit(row.supplierPaymentId)}>
                            <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                        </IconButton>
                        <IconButton onClick={() => onRemoveDraftSupplierPayment(row.supplierPaymentId)}>
                            <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                        </IconButton>
                    </>
                )}
                {row.paymentStatus === 'Approved' && (
                    <IconButton onClick={() => onNavigatingToEdit(row.supplierPaymentId)}>
                        <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                    </IconButton>
                )}
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToEdit(row.supplierPaymentId)}
                sx={{
                    '&:hover': {
                        color: 'action.active',
                        cursor: 'pointer',
                    },
                }}>
                {row.paymentNo}
            </Link>
        )
    }

    const BooleanFormatter = ({ value }) => <span>{formatBooleanValue(value)}</span>
    const formatBooleanValue = (value) => (value ? 'Yes' : 'No')
    const BooleanTypeProvider = (props) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />

    const hideDialogAndRefreshDraftSupplierPaymentList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Draft Supplier Payment removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onRemoveDraftSupplierPayment = async (supplierPaymentId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteDraftSupplierPayment({ supplierPaymentId: supplierPaymentId }))
                hideDialogAndRefreshDraftSupplierPaymentList()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'paymentNo', title: 'Payment No', getCellValue: renderLinkCell },
        { name: 'supplierFirstName', title: 'First Name' },
        { name: 'supplierLastName', title: 'Last Name' },
        { name: 'paymentDate', title: 'Payment Date', getCellValue: (row) => formatDateTime(row.paymentDate) },
        { name: 'cashPayment', title: 'Cash Payment', getCellValue: (row) => formatCurrency(row.cashAmount) },
        {
            name: 'creditCardPayment',
            title: 'Credit Payment',
            getCellValue: (row) => formatCurrency(row.creditCardAmount),
        },
        { name: 'otherPayment', getCellValue: (row) => formatCurrency(row.othersAmount), title: 'Other Payment' },
        { name: 'totalPaid', getCellValue: (row) => formatCurrency(row.totalPaid), title: 'Total Paid' },
        { name: 'transcationBy', title: 'Txn By' },
        { name: 'paymentStatus', title: 'Status' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            {
                columnName: 'paymentNo',
                width: min ? '120px' : '11%',
                minWidth: '120px',
                wordWrapEnabled: true,
            },
            { columnName: 'supplierFirstName', wordWrapEnabled: true, width: min ? '100px' : '10%', minWidth: '100px' },
            { columnName: 'supplierLastName', wordWrapEnabled: true, width: min ? '100px' : '10%', minWidth: '100px' },
            { columnName: 'paymentDate', wordWrapEnabled: true, width: min ? '110px' : '12%', minWidth: '110px' },
            {
                columnName: 'cashPayment',
                align: 'right',
                wordWrapEnabled: true,
                width: min ? '110px' : '8%',
                minWidth: '110px',
            },
            {
                columnName: 'creditCardPayment',
                align: 'right',
                wordWrapEnabled: true,
                width: min ? '110px' : '9%',
                minWidth: '110px',
            },
            {
                columnName: 'otherPayment',
                align: 'right',
                wordWrapEnabled: true,
                width: min ? '110px' : '9%',
                minWidth: '110px',
            },
            {
                columnName: 'totalPaid',
                align: 'right',
                wordWrapEnabled: true,
                width: min ? '110px' : '8%',
                minWidth: '110px',
            },
            {
                columnName: 'transcationBy',
                align: 'left',
                wordWrapEnabled: true,
                width: min ? '100px' : '7%',
                minWidth: '100px',
            },
            {
                columnName: 'paymentStatus',
                align: 'left',
                wordWrapEnabled: true,
                width: min ? '90px' : '7%',
                minWidth: '90px',
            },
            { columnName: 'Action', align: 'center', width: min ? '90px' : '9%', minWidth: '90px' },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={supplierPayments.data} columns={columns}>
                <BooleanTypeProvider for={booleanColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={supplierPayments.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default SuppliersPaymentDataGrid
