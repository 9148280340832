let FreightAmountPaidByTypeService = {}

FreightAmountPaidByTypeService.setFreightAmountPaidByTypeList = [
    { id: 0, freightAmountPaidByType: 'Customer' },
    { id: 10, freightAmountPaidByType: 'Supplier' },
]

FreightAmountPaidByTypeService.getFreightAmountPaidByType = () => {
    return FreightAmountPaidByTypeService.setFreightAmountPaidByTypeList
}

FreightAmountPaidByTypeService.getGenderTypeData = (row) => {
    const freightAmountPaidByType = FreightAmountPaidByTypeService.setFreightAmountPaidByTypeList.find(
        (item) => item.id === row.freightAmountPaidByType
    )
    return freightAmountPaidByType ? freightAmountPaidByType.freightAmountPaidByType : ''
}

export default FreightAmountPaidByTypeService
