import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { addSupplierPayment } from 'app/redux/store/features/suppliers.js'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import AddDraftSuppliersPaymentForm from './AddDraftSuppliersPaymentForm'
import { defaultDraftSuppliersPayment } from './defaultDraftSuppliersPayment'
import utilServices from 'app/services/util-services'
import inventoryService from 'app/services/pages/store/inventory/inventoryServices'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const AddDraftSuppliersPayment = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.suppliers)
    const supplierId = location.state?.supplierId || null
    const today = new Date()

    const [draftSuppliersPaymentData] = useState({
        ...defaultDraftSuppliersPayment,
        paymentDate: today,
        fkSupplierId: supplierId,
    })
    const [fkSupplierId, setFkSupplierId] = useState(null)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Draft Supplier Payment added successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate(supplierId ? '/store/suppliers/suppliers' : '/store/suppliers/draftsupplierspayments')
        }
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const ChartAccountLookupList = useCallback(async (searchTerm) => {
        try {
            return await inventoryService.getChartAccountLookupList({ accountName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ChartAccountLookupById = useCallback(async (key) => {
        try {
            return await inventoryService.getChartAccountLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ChartAccountStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'chartAccountId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await ChartAccountLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await ChartAccountLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const chartAccountEditorOptions = {
        dataSource: ChartAccountStore,
        displayExpr: 'accountName',
        valueExpr: 'chartAccountId',
        searchEnabled: true,
        showClearButton: true,
    }

    const onAddDraftSuppliersPayment = async () => {
        const cashAmount = parseFloat(formatCurrency(draftSuppliersPaymentData.cashAmount || 0))
        const othersAmount = parseFloat(formatCurrency(draftSuppliersPaymentData.othersAmount || 0))
        const creditCardAmount = parseFloat(formatCurrency(draftSuppliersPaymentData.creditCardAmount || 0))
        const totalAmount = cashAmount + othersAmount + creditCardAmount

        if (totalAmount <= 0) {
            enqueueSnackbar('Please enter a valid amount greater than zero', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            return
        }

        setApiCallInProgress(true)

        const finalFkSupplierId = supplierId || fkSupplierId

        dispatch(
            addSupplierPayment({
                ...draftSuppliersPaymentData,
                fkSupplierId: finalFkSupplierId,
            })
        )
    }

    return (
        <React.Fragment>
            <AddDraftSuppliersPaymentForm
                draftSuppliersPaymentData={draftSuppliersPaymentData}
                addDraftSuppliersPayment={onAddDraftSuppliersPayment}
                setFkSupplierId={setFkSupplierId}
                supplierId={supplierId}
                chartAccountEditorOptions={chartAccountEditorOptions}
            />
        </React.Fragment>
    )
}

export default AddDraftSuppliersPayment
