import React, { useEffect, useState, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import { CustomPaging, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useDispatch, useSelector } from 'react-redux'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useSnackbar } from 'notistack'
import { unLinkUserBranch } from 'app/redux/store/features/users'
import { useJumboTheme } from '@jumbo/hooks'

const LinkedCompanyBranchGrid = ({
    userId,
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const { linkedBranches, loading, error } = useSelector((state) => state.users)
    const [sorting, setSorting] = useState([{ columnName: 'companyName', direction: 'asc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onRemoveCompanyBranch(row.companyId, row.branchId)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const hideDialogAndRefreshCompanyBranchList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Linked Company Branch removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onRemoveCompanyBranch = async (companyId, branchId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(unLinkUserBranch({ userId: userId, companyId: companyId, branchId: branchId }))
                hideDialogAndRefreshCompanyBranchList()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'companyName', title: 'Company Name' },
        { name: 'contactName', title: 'Contact Name' },
        { name: 'branchName', title: 'Branch Name' },
        { name: 'branchType', title: 'Branch Type' },
        { name: 'address', title: 'Address' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            {
                columnName: 'companyName',
                wordWrapEnabled: true,
                width: min ? '160px' : '16%',
                minWidth: '160px',
            },
            {
                columnName: 'contactName',
                wordWrapEnabled: true,
                width: min ? '160px' : '16%',
                minWidth: '160px',
            },
            {
                columnName: 'branchName',
                wordWrapEnabled: true,
                width: min ? '140px' : '14%',
                minWidth: '140px',
            },
            {
                columnName: 'branchType',
                wordWrapEnabled: true,
                width: min ? '140px' : '14%',
                minWidth: '140px',
            },
            {
                columnName: 'address',
                wordWrapEnabled: true,
                width: min ? '200px' : '32%',
                minWidth: '200px',
            },
            {
                columnName: 'Action',
                wordWrapEnabled: true,
                width: min ? '110px' : '8%',
                align: 'center',
                minWidth: '110px',
            },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [
        { columnName: 'Action', sortingEnabled: false },
        { columnName: 'branchType', sortingEnabled: false },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={linkedBranches.data} columns={columns}>
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={linkedBranches.totalCount} />
                    <Table columnExtensions={columnWidths} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <TableSelection showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default LinkedCompanyBranchGrid
