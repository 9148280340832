import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, {
    ButtonItem,
    SimpleItem,
    EmptyItem,
    ColCountByScreen,
    GroupItem,
    TabbedItem,
    TabPanelOptions,
    Tab,
    Label,
} from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import { PatternRule, RequiredRule } from 'devextreme-react/data-grid'
import { useMediaQuery } from '@mui/material'

const AddSupplierForm = (props) => {
    const navigate = useNavigate()
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })
    const { loading } = useSelector((state) => state.suppliers)

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/store/suppliers/suppliers'),
    }

    const phoneEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /\d/,
        },
    }
    const phonePattern = /^\+\d{10,15}$/

    const validationRules = {
        //companyName: [{ type: 'required', message: 'Company name is required.' }],
        firstName: [{ type: 'required', message: 'First name is required.' }],
        email: [
            //{ type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Email is invalid.' },
        ],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Add Supplier
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.addSupplier)}>
                    <Form
                        mode='form'
                        formData={props.supplierData}
                        showColonAfterLabel={false}
                        labelLocation={labelLocation}>
                        <GroupItem>
                            <TabbedItem>
                                <TabPanelOptions deferRendering={false} />
                                <Tab title='Supplier Details'>
                                    <GroupItem>
                                        <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                                        <GroupItem caption='Personal Info' cssClass='form-group-item'>
                                            <GroupItem cssClass='form-group-item-margin'>
                                                <GroupItem colCount={2}>
                                                    <SimpleItem dataField='isTaxExempted' editorType='dxCheckBox'>
                                                        <Label text='Tax Exempted' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='registeredForAdvancedTax'
                                                        editorType='dxCheckBox'>
                                                        <Label text='Registered for Advance Tax' />
                                                    </SimpleItem>
                                                </GroupItem>
                                                <SimpleItem dataField='supplierCode' />
                                                <SimpleItem
                                                    dataField='companyName'
                                                    validationRules={validationRules.companyName}
                                                />
                                                <SimpleItem
                                                    dataField='firstName'
                                                    validationRules={validationRules.firstName}
                                                />
                                                <SimpleItem dataField='lastName' />
                                                <SimpleItem dataField='barcode' />
                                                <SimpleItem dataField='abn'>
                                                    <Label text='ABN' />
                                                </SimpleItem>
                                                <SimpleItem dataField='email' validationRules={validationRules.email} />
                                                <SimpleItem
                                                    dataField='fkSupplierCompanyId'
                                                    editorType='dxSelectBox'
                                                    editorOptions={props.companyEditorOptions}>
                                                    <Label text='Supplier Company' />
                                                </SimpleItem>
                                            </GroupItem>
                                        </GroupItem>
                                        <GroupItem>
                                            <GroupItem caption='Contact Info' cssClass='form-group-item '>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem dataField='phoneNo' editorOptions={phoneEditorOptions}>
                                                        <RequiredRule message='Phone number is required.' />
                                                        <PatternRule
                                                            message='phone number must start with + contain 10 to 15 digits'
                                                            pattern={phonePattern}
                                                        />
                                                    </SimpleItem>
                                                    <SimpleItem dataField='addressLine1'>
                                                        <Label text='Address Line One' />
                                                    </SimpleItem>
                                                    <SimpleItem dataField='addressLine2'>
                                                        <Label text='Address Line two' />
                                                    </SimpleItem>
                                                </GroupItem>
                                            </GroupItem>
                                            <GroupItem caption='State Info' cssClass='form-group-item '>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem dataField='country' />
                                                    <SimpleItem dataField='state' />
                                                    <SimpleItem dataField='suburb' />
                                                    <SimpleItem dataField='postCode' />
                                                </GroupItem>
                                            </GroupItem>
                                        </GroupItem>
                                    </GroupItem>
                                </Tab>
                            </TabbedItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddSupplierForm
