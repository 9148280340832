if (!String.prototype.toFilterStartDate) {
    String.prototype.toFilterStartDate = function () {
        const parsedDate = new Date(this)
        if (isNaN(parsedDate.getTime())) {
            throw new Error(`Invalid date string: ${this}`)
        }
        parsedDate.setHours(0, 0, 0, 0)
        return parsedDate
    }
}

if (!String.prototype.toFilterEndDate) {
    String.prototype.toFilterEndDate = function () {
        const parsedDate = new Date(this)
        if (isNaN(parsedDate.getTime())) {
            throw new Error(`Invalid date string: ${this}`)
        }
        parsedDate.setHours(23, 59, 59, 999)
        return parsedDate
    }
}

if (!String.prototype.toFilterStartDateTime) {
    String.prototype.toFilterStartDateTime = function (setDefaulttime = false) {
        const parsedDate = new Date(this)
        if (isNaN(parsedDate.getTime())) {
            throw new Error(`Invalid date string: ${this}`)
        }
        if (setDefaulttime) {
            console.log('setDefaulttime', setDefaulttime)
            parsedDate.setHours(0, 0, 0, 0)
        }

        return parsedDate
    }
}

if (!String.prototype.toFilterEndDateTime) {
    String.prototype.toFilterEndDateTime = function (setDefaulttime = false) {
        const parsedDate = new Date(this)
        if (isNaN(parsedDate.getTime())) {
            throw new Error(`Invalid date string: ${this}`)
        }
        if (setDefaulttime) {
            parsedDate.setHours(23, 59, 59, 999)
        }

        return parsedDate
    }
}

const DateUtils = {
    getStartDate: (days) => {
        const today = new Date()
        const startingDate = new Date(today)
        startingDate.setDate(today.getDate() - (days || 10))
        startingDate.setHours(0, 0, 0, 0)
        return startingDate
    },

    getOrdertDate: (days) => {
        const today = new Date()
        const startingDate = new Date(today)
        startingDate.setDate(today.getDate() + days)
        return startingDate
    },

    getEndDate: (days) => {
        const today = new Date()
        const endingDate = new Date(today)
        if (days) endingDate.setDate(today.getDate() + days)
        endingDate.setHours(23, 59, 59, 999)
        return endingDate
    },

    getUtcStartDate: (days) => {
        const today = new Date()
        const startingDate = new Date(today)
        startingDate.setDate(today.getDate() - (days || 7))
        startingDate.setUTCHours(0, 0, 0, 0)
        return startingDate
    },

    getUtcEndDate: () => {
        const today = new Date()
        const endingDate = new Date(today)
        endingDate.setUTCHours(23, 59, 59, 999)
        return endingDate
    },
}

export default DateUtils
