import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    suppliers: { data: [], totalCount: 0 },
    supplierPayments: { data: [], totalCount: 0 },
    supplierAuditLogs: { data: [], totalCount: 0 },
    supplierLookups: { data: [], totalCount: 0 },
    supplierCompanyLookups: { data: [], totalCount: 0 },
    loading: false,
    error: null,
    refreshing: false,
}

const supplierSlice = createSlice({
    name: 'suppliers',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getSuppliers: (state, action) => {
            state.suppliers = action.payload
            state.loading = false
            state.refreshing = false
        },
        getSuppliersPayment: (state, action) => {
            state.supplierPayments = action.payload
            state.loading = false
            state.refreshing = false
        },
        getSupplierAuditLogs: (state, action) => {
            state.supplierAuditLogs = action.payload
            state.loading = false
            state.refreshing = false
        },
        getSupplierLookup: (state, action) => {
            state.supplierLookups = action.payload
            state.loading = false
        },
        getExistingSupplierById: (state, action) => {
            const index = state.suppliers.data.findIndex(
                (supplier) => supplier.supplierId === action.payload.supplierId
            )
            if (index >= 0) state.suppliers.data[index] = action.payload
            else {
                state.suppliers.data.push(action.payload)
                state.suppliers.totalCount++
            }
            state.loading = false
        },
        getExistingSuppliersPaymentById: (state, action) => {
            const index = state.supplierPayments.data.findIndex(
                (supplierPayment) => supplierPayment.supplierPaymentId === action.payload.supplierPaymentId
            )
            if (index >= 0) state.supplierPayments.data[index] = action.payload
            else {
                state.supplierPayments.data.push(action.payload)
                state.supplierPayments.totalCount++
            }
            state.loading = false
        },
        getSupplierCompanyLookup: (state, action) => {
            state.supplierCompanyLookups = action.payload
            state.loading = false
        },
        getExistingSupplierCompanyLookupById: (state, action) => {
            const index = state.supplierCompanyLookups.data.findIndex(
                (supplier) => supplier.supplierCompanyId === action.payload.supplierCompanyId
            )
            if (index >= 0) state.supplierCompanyLookups.data[index] = action.payload
            else {
                state.supplierCompanyLookups.data.push(action.payload)
                state.supplierCompanyLookups.totalCount++
            }
            state.loading = false
        },
        addNewSupplier: (state, action) => {
            state.suppliers.data.push(action.payload)
            state.suppliers.totalCount++
            state.loading = false
        },
        updateExistingSupplier: (state, action) => {
            const index = state.suppliers.data.findIndex(
                (supplier) => supplier.supplierId === action.payload.supplierId
            )
            if (index >= 0) state.suppliers.data[index] = action.payload
            state.loading = false
        },
        updateExistingDraftSupplierPayment: (state, action) => {
            const index = state.supplierPayments.data.findIndex(
                (supplierPayment) => supplierPayment.supplierPaymentId === action.payload.supplierPaymentId
            )
            if (index >= 0) state.supplierPayments.data[index] = action.payload
            state.loading = false
        },
        removeSupplier: (state, action) => {
            const index = state.suppliers.data.findIndex(
                (supplier) => supplier.supplierId === action.payload.supplierId
            )
            if (index >= 0) {
                state.suppliers.data.splice(index, 1)
                state.suppliers.totalCount--
            }
            state.loading = false
        },
        removeDraftSupplierPayment: (state, action) => {
            const index = state.supplierPayments.data.findIndex(
                (supplierPayment) => supplierPayment.supplierPaymentId === action.payload.supplierPaymentId
            )
            if (index >= 0) {
                state.supplierPayments.data.splice(index, 1)
                state.supplierPayments.totalCount--
            }
            state.loading = false
        },
        addNewSupplierPayment: (state, action) => {
            state.supplierPayments.data.push(action.payload)
            state.supplierPayments.totalCount++
            state.loading = false
        },
        updateExistingSuplierPaymentStatus: (state, action) => {
            const index = state.supplierPayments.data.findIndex(
                (supplierPayment) => supplierPayment.supplierPaymentId === action.payload.supplierPaymentId
            )
            if (index >= 0) {
                state.supplierPayments.data[index] = action.payload
                state.loading = false
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.supplierId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
const selectSupplierPaymentById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.supplierPaymentId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})

export { selectItemById, selectSupplierPaymentById }

export const {
    apiRequested,
    refreshRequested,
    apiRequestedFailed,
    getSuppliers,
    getSuppliersPayment,
    getSupplierAuditLogs,
    getSupplierLookup,
    getExistingSupplierById,
    getExistingSuppliersPaymentById,
    getSupplierCompanyLookup,
    getExistingSupplierCompanyLookupById,
    addNewSupplier,
    updateExistingSupplier,
    updateExistingDraftSupplierPayment,
    removeSupplier,
    removeDraftSupplierPayment,
    addNewSupplierPayment,
    updateExistingSuplierPaymentStatus,
} = supplierSlice.actions
export default supplierSlice.reducer

export const getSupplierList = (filter, refresh = false) => {
    let url = '/shipment/Supplier/list'
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getSuppliers.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSupplierAuditLogList = (filter, refresh = false) => {
    let url = '/shipment/Supplier/auditlogs/list'
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getSupplierAuditLogs.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSupplierLookupList = (filter) => {
    let url = filter?.supplierName
        ? `shipment/Supplier/lookup/list?search=${filter.supplierName}`
        : `shipment/Supplier/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSupplierLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSupplierById = (filter) => {
    let url = `/shipment/Supplier/${filter.supplierId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingSupplierById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSuppliersPaymentById = (filter) => {
    let url = `/shipment/Supplier/payment/${filter.supplierPaymentId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingSuppliersPaymentById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSupplierCompanyLookupList = (filter) => {
    let url = filter?.supplierCompanyName
        ? `/shipment/Supplier/company/lookup/list?search=${filter.supplierCompanyName}`
        : `/shipment/Supplier/company/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSupplierCompanyLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSupplierCompanyLookupById = (filter) => {
    let url = `/shipment/Supplier/company/lookup/${filter.supplierCompanyId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingSupplierCompanyLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addSupplier = (supplier) => {
    let url = `/shipment/Supplier/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: supplier,
        onStart: apiRequested.type,
        onSuccess: addNewSupplier.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateSupplier = (supplier) => {
    let url = `/shipment/Supplier/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: supplier,
        onStart: apiRequested.type,
        onSuccess: updateExistingSupplier.type,
        onError: apiRequestedFailed.type,
    })
}

export const deleteSupplier = (supplier) => {
    let url = '/shipment/Supplier/remove'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: supplier,
        onStart: apiRequested.type,
        onSuccess: removeSupplier.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSupplierPaymentList = (filter, refresh = false) => {
    let url = '/shipment/Supplier/payment/list'
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getSuppliersPayment.type,
        onError: apiRequestedFailed.type,
    })
}

export const addSupplierPayment = (supplier) => {
    let url = `/shipment/Supplier/payment/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: supplier,
        onStart: apiRequested.type,
        onSuccess: addNewSupplierPayment.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateSupplierDraftPayment = (supplierPayment) => {
    let url = `/shipment/Supplier/payment/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: supplierPayment,
        onStart: apiRequested.type,
        onSuccess: updateExistingDraftSupplierPayment.type,
        onError: apiRequestedFailed.type,
    })
}

export const deleteDraftSupplierPayment = (supplier) => {
    let url = '/shipment/Supplier/payment/remove'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: supplier,
        onStart: apiRequested.type,
        onSuccess: removeDraftSupplierPayment.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateSuplierPaymentStatus = (supplierPayment) => {
    let url = `/shipment/Supplier/payment/update/status`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: supplierPayment,
        onStart: apiRequested.type,
        onSuccess: updateExistingSuplierPaymentStatus.type,
        onError: apiRequestedFailed.type,
    })
}
