import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import notify from 'devextreme/ui/notify'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import Div from '@jumbo/shared/Div'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import RadioGroup from 'devextreme-react/radio-group'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import TextArea from 'devextreme-react/text-area'
import { UpdatePaymentStatus } from 'app/redux/store/features/customers'

const DraftPaymentApprovedStatus = ({ customerPaymentId }) => {
    const { hideDialog } = useJumboDialog()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.customers)

    const [selectionPriority, setSelectionPriority] = useState('Approved')
    const [comment, setComment] = useState('')

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) {
            handleError(error)
            return
        }
        hideDialogAndRefreshList()
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                notify({ message: error.detail, width: 300, position: 'top right' }, 'error', 1500)
            } else {
                notify({ message: error.detail, width: 300, position: 'top right' }, 'error', 1500)
            }
        },
        [loading]
    )

    const hideDialogAndRefreshList = () => {
        hideDialog()
        notify({ message: 'Status Updated successfully', width: 300, position: 'top right' }, 'success', 1500)
    }

    const updateStatusMutation = useMutation((data) => {
        setApiCallInProgress(true)
        dispatch(UpdatePaymentStatus(data))
    })

    const onFormSubmit = () => {
        updateStatusMutation.mutate({
            customerPaymentId: customerPaymentId,
            paymentStatus: selectionPriority,
            approvedComments: comment,
            cancelledReason: null,
        })
    }

    const changeSelectionPriority = useCallback(
        (e) => {
            setSelectionPriority(e.value)
        },
        [setSelectionPriority]
    )

    const handleCommentChange = useCallback((e) => {
        setComment(e.value)
    }, [])

    return (
        <Div>
            <Div>
                <Div sx={{ m: 3, borderBottom: 1, borderColor: 'success.main', paddingBottom: 3 }}>
                    <Typography sx={{ mb: 1 }}>Approve or cancel payment entry</Typography>
                    <RadioGroup
                        items={[
                            { id: 'Approved', text: 'Approved' },
                            { id: 'Cancel', text: 'Cancel' },
                        ]}
                        value={selectionPriority}
                        layout='horizontal'
                        valueExpr='id'
                        displayExpr='text'
                        // onValueChanged={changeSelectionPriority}
                    />
                </Div>
                <Div sx={{ m: 3 }}>
                    <Typography>Comment</Typography>
                    <TextArea value={comment} height={90} onValueChanged={handleCommentChange} />
                </Div>
                <Stack direction={'row'} spacing={2} sx={{ m: 'auto', ml: 10.5, mb: 2 }}>
                    <LoadingButton
                        variant='contained'
                        color='success'
                        onClick={onFormSubmit}
                        size='large'
                        sx={{ width: 100 }}
                        disabled={!selectionPriority || apiCallInProgress}>
                        Save
                    </LoadingButton>
                    <LoadingButton variant='outlined' color='success' onClick={hideDialog} size='large'>
                        Cancel
                    </LoadingButton>
                </Stack>
            </Div>
        </Div>
    )
}

export default DraftPaymentApprovedStatus
