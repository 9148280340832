import React, { useState, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, Label, GroupItem } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import { useNavigate } from 'react-router-dom'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { updateBranchConfig, getBranchConfig } from 'app/redux/store/features/configs'
import { useMediaQuery } from '@mui/material'

const BranchConfig = () => {
    const { handleSubmit } = useForm()
    const { showDialog, hideDialog } = useJumboDialog()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { branchConfig, loading, error } = useSelector((state) => state.config)
    const [configData, setConfigData] = useState({ ...branchConfig })
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    useEffect(() => {
        if (!apiCallInProgress || !error) return
        setApiCallInProgress(false)
        if (error) handleError(error)
    }, [error, loading])

    useEffect((e) => {
        setApiCallInProgress(true)
        function getBranch() {
            dispatch(getBranchConfig())
        }
        getBranch()
    }, [])

    useEffect(() => {
        setConfigData({ ...branchConfig })
    }, [branchConfig])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const reloadBranchConfig = () => {
        dispatch(getBranchConfig())
    }

    const dialogBoxButtons = () => (
        <Stack direction='row' spacing={2} sx={{ m: 'auto', ml: 10.5, mb: 2 }}>
            <LoadingButton
                variant='contained'
                color='secondary'
                onClick={async () => {
                    await reloadBranchConfig()
                    hideDialog()
                }}
                size='large'
                sx={{ width: 100 }}>
                YES
            </LoadingButton>
            <LoadingButton variant='outlined' color='secondary' onClick={hideDialog} size='large' sx={{ width: 100 }}>
                NO
            </LoadingButton>
        </Stack>
    )

    const handleFieldChange = (e) => {
        setConfigData((prev) => ({
            ...prev,
            [e.dataField]: e.value,
        }))
    }

    const onUpdateBranchConfig = useCallback(async () => {
        setApiCallInProgress(true)
        const responseData = dispatch(updateBranchConfig(configData))
        if (responseData) {
            enqueueSnackbar('Branch config updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }, [dialogBoxButtons])

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color='common.white' variant='primary'>
                        Branch Config
                    </Typography>
                }
                sx={{ m: 0.5, mt: 1 }}
                headerSx={{
                    height: 40,
                    bgcolor: 'secondary.main',
                }}>
                <form onSubmit={handleSubmit(onUpdateBranchConfig)}>
                    <Form
                        mode='form'
                        onFieldDataChanged={handleFieldChange}
                        showColonAfterLabel={false}
                        showValidationSummary={false}
                        deferRendering={false}
                        formData={configData}
                        labelLocation={labelLocation}
                        readOnly={true}>
                        <GroupItem caption='Configuration' cssClass='form-group-item'>
                            <GroupItem cssClass='form-group-item-margin'>
                                <ColCountByScreen lg={13} md={1} sm={1} xs={1} />
                                <GroupItem />
                                <GroupItem />
                                <GroupItem />
                                <GroupItem colSpan={6}>
                                    <SimpleItem dataField='isTaxOnSubTotal' editorType='dxCheckBox'>
                                        <Label text='Enable Tax on SubTotal' />
                                    </SimpleItem>
                                    <SimpleItem dataField='enabledBoxQtyForOrder' editorType='dxCheckBox'>
                                        <Label text='Enable Box Qty for Order' />
                                    </SimpleItem>
                                    <SimpleItem dataField='enableAdvanceTax' editorType='dxCheckBox'>
                                        <Label text='Enable Advance Tax' />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField='registeredAdvanceTax'
                                        editorType='dxNumberBox'
                                        editorOptions={{
                                            showSpinButtons: true,
                                            format: {
                                                type: 'fixedPoint',
                                                precision: 2,
                                            },
                                            min: 0,
                                        }}>
                                        <Label text='Tax when Registered' />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField='notRegisteredAdvanceTax'
                                        editorType='dxNumberBox'
                                        editorOptions={{
                                            showSpinButtons: true,
                                            format: {
                                                type: 'fixedPoint',
                                                precision: 2,
                                            },
                                            min: 0,
                                        }}>
                                        <Label text='Tax When Not Registered' />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default BranchConfig
