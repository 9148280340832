// Dashboard
export const CanLogin = 10
export const CanViewAdminDashboard = 11
export const CanViewUserDashboard = 12

// Security
export const CanViewSecurity = 30
export const CanViewUsers = 31
export const CanAddUser = 32
export const CanUpdateUser = 33
export const CanViewPermissions = 40
export const CanEditPermissions = 41

// Store
export const CanViewStore = 50
export const CanViewInventoryList = 51
export const CanEditInventoryList = 52

export const CanViewCustomers = 55

export const CanViewSales = 60
export const CanEditSales = 61

export const CanViewShipments = 70
export const CanEditShipments = 71
export const CanViewSuppliers = 70 // 

export const CanViewBranchSettings = 75
export const CanEditBranchSettings = 76

export const CanViewBranchDefinitions = 77
export const CanEditBranchDefinitions = 78

// Reports
export const CanViewBranchReports = 80

// Settings
export const CanViewSettings = 100
export const CanViewCompanyList = 101
export const CanViewGlobalConfig = 102
export const CanViewBranchLocations = 110
export const CanEditBranchLocations = 111

// Company
export const canViewCompany = 45
export const canViewAuditLogs = 46
export const canViewTransfers = 47
