let InventoryBasePriceService = {}

InventoryBasePriceService.setBasePriceList = [
    { id: 1, inventoryBasePrice: 'Default' },
    { id: 2, inventoryBasePrice: 'Sale Price' },
    { id: 3, inventoryBasePrice: 'Order Price' },
]

InventoryBasePriceService.getBasePriceServiceById = (id) => {
    const basePrice = InventoryBasePriceService.setBasePriceList.find((item) => item.id === id)
    return basePrice ? basePrice.inventoryBasePrice : ''
}

InventoryBasePriceService.getBasePriceService = () => {
    return InventoryBasePriceService.setBasePriceList
}

export default InventoryBasePriceService
