import React from 'react'
import Grid from '@mui/material/Grid'
import LeftInfoCard from './components/LeftInfoCard'
import RightInfoCard from './components/RightInfoCard'

const InfoBar = ({ order, onlineShipment }) => {
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} sm={12} lg={6}>
                <LeftInfoCard order={order} />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <RightInfoCard order={order} onlineShipment={onlineShipment} />
            </Grid>
        </Grid>
    )
}

export default InfoBar
