export const defaultDraftSuppliersPayment = {
    cashAmount: 0,
    creditCardAmount: 0,
    othersAmount: 0,
    fkCashChartAccountId: null,
    fkCreditChartAccountId: null,
    fkOtherChartAccountId: null,
    fkSupplierId: null,
    paymentReferenceNo: null,
    comments: null,
}
