import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomerCategoryLookupList } from 'app/redux/store/features/customers'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { useMediaQuery } from '@mui/material'

const UpdateCustomerForm = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { handleSubmit } = useForm()
    const { customerCategoryLookups, loading } = useSelector((state) => state.customers)
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    useEffect(() => {
        dispatch(getCustomerCategoryLookupList(''))
    }, [])

    const CustomerCategoryLookupStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'customerCategoryId',
                    data: customerCategoryLookups.data,
                    totalCount: customerCategoryLookups.length,
                }),
                reshapeOnPush: true,
            }),
        [customerCategoryLookups]
    )

    const CustomerCategoryLookupEditorOptions = {
        dataSource: CustomerCategoryLookupStore,
        displayExpr: 'name',
        valueExpr: 'customerCategoryId',
        searchEnabled: true,
        showClearButton: true,
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }
    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/store/customers'),
    }

    const validationRules = {
        firstName: [{ type: 'required', message: 'First name is required.' }],
        lastName: [{ type: 'required', message: 'Last name is required.' }],
        email: [
            { type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Email is invalid.' },
        ],
        mobileNo: [{ type: 'required', message: 'Mobile number is required.' }],
    }

    const messageEditorOptions = { height: 90, maxLength: 200 }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(props.updateCustomer)} cssClass='form-group-button-width'>
                <Form
                    mode='form'
                    formData={props.customer}
                    showColonAfterLabel={false}
                    cssClass='form-group-button-width'
                    labelLocation={labelLocation}>
                    <GroupItem>
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                            <GroupItem caption='Personal Info' cssClass='form-group-item'>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <GroupItem colCount={2}>
                                        <SimpleItem dataField='isWholesaleCustomer' editorType='dxCheckBox'>
                                            <Label text='Whole-Sale Customer' />
                                        </SimpleItem>
                                        <SimpleItem dataField='isTaxExempted' editorType='dxCheckBox'>
                                            <Label text='Tax Exempted' />
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem colCount={2}>
                                        <SimpleItem dataField='registeredForAdvancedTax' editorType='dxCheckBox'>
                                            <Label text='Registered for Advance Tax' />
                                        </SimpleItem>
                                        <SimpleItem dataField='isLoyaltyEnabled' editorType='dxCheckBox'>
                                            <Label text='Loyalty' />
                                        </SimpleItem>
                                    </GroupItem>
                                    <SimpleItem dataField='customerCode' />
                                    <SimpleItem dataField='firstName' validationRules={validationRules.firstName} />
                                    <SimpleItem dataField='lastName' validationRules={validationRules.lastName} />
                                    <SimpleItem dataField='cnIcNumber'>
                                        <Label text='CNIC Number' />
                                    </SimpleItem>
                                    <SimpleItem dataField='taxFileNumber' />
                                    <SimpleItem
                                        dataField='fkCustomerCategoryId'
                                        editorType='dxSelectBox'
                                        editorOptions={CustomerCategoryLookupEditorOptions}>
                                        <Label text='Customer Category' />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem caption='Contact Info' cssClass='form-group-item '>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <SimpleItem dataField='mobileNo' validationRules={validationRules.mobileNo} />
                                    <SimpleItem dataField='addressLine1'>
                                        <Label text='Address Line One' />
                                    </SimpleItem>
                                    <SimpleItem dataField='addressLine2'>
                                        <Label text='Address Line two' />
                                    </SimpleItem>
                                    <SimpleItem dataField='email' validationRules={validationRules.email} />
                                    <SimpleItem dataField='area' />
                                    <SimpleItem dataField='city' />
                                    <SimpleItem
                                        dataField='comments'
                                        editorType='dxTextArea'
                                        editorOptions={messageEditorOptions}>
                                        <Label text='Notes' />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                            <GroupItem caption='State Info' cssClass='form-group-item '>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <SimpleItem dataField='country' />
                                    <SimpleItem dataField='suburb' />
                                    <SimpleItem dataField='state' />
                                    <SimpleItem dataField='postCode' />
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                    </GroupItem>
                    <EmptyItem />
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                        <ButtonItem
                            buttonOptions={{
                                disabled: loading,
                                icon: loading ? 'refresh' : 'check',
                                ...submitButtonOptions,
                            }}
                            horizontalAlignment='right'
                        />
                        <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                    </GroupItem>
                </Form>
            </form>
        </React.Fragment>
    )
}

export default UpdateCustomerForm
