import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from 'react-query'
import Div from '@jumbo/shared/Div'
import DailySaleSummaryReportLayout from './components/DailySaleSummaryReportLayout'
import reportDataServices from 'app/services/pages/reports/report-data-services'
import { getDailySaleSummaryReport } from 'app/redux/store/features/reports'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'

const DailySaleSummaryReport = () => {
    const dispatch = useDispatch()
    const itemsPerPage = 25
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [reportFilter, setReportFilter] = useState({
        startDate: DateUtils.getUtcStartDate(10).toISOString(),
        endDate: DateUtils.getUtcEndDate().toISOString(),
        CheckoutById: null,
        ServedById: null,
        SaleTypeId: null,
    })
    const [imageData, setImageData] = useState('')

    const applyViewFilter = useMutation({
        mutationFn: async (viewFilter) => {
            const filter = { ...viewFilter, skip: 0, take: itemsPerPage }
            setReportFilter(filter)
            dispatch(getDailySaleSummaryReport(filter))
        },
    })

    const applyPageChange = useMutation({
        mutationFn: async (page) => {
            const filter = { ...reportFilter, skip: (page - 1) * itemsPerPage, take: itemsPerPage }
            setReportFilter(filter)
            dispatch(getDailySaleSummaryReport(filter))
        },
    })

    const applyPdfFilter = useMutation({
        mutationFn: async () => {
            const filter = { ...reportFilter, skip: 0, take: 2000 }
            setReportFilter(filter)
            dispatch(getDailySaleSummaryReport(filter))
        },
    })

    useEffect(async () => {
        // We load the logo only first time or on full refresh
        setApiCallInProgress(true)
        const image = await reportDataServices.getImage()
        const imageData = image.data ? image.data : ''
        setImageData(imageData)
        dispatch(getDailySaleSummaryReport({ ...reportFilter, skip: 0, take: itemsPerPage }))
    }, [dispatch])
    return (
        <Div>
            <DailySaleSummaryReportLayout
                imageData={imageData}
                viewMutation={applyViewFilter}
                pdfMutation={applyPdfFilter}
                pageMutation={applyPageChange}
                apiCallInProgress={apiCallInProgress}
                setApiCallInProgress={setApiCallInProgress}
            />
        </Div>
    )
}

export default DailySaleSummaryReport
