import React, { useEffect, useState, useMemo } from 'react'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import useJumboTheme from '../../../../../../../../@jumbo/hooks/useJumboTheme'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton } from '@mui/material'

const PaymentsData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { payments, loading, error } = useSelector((state) => state.customers)
    const dateColumns = ['paymentDate']
    const [sorting, setSorting] = useState([{ columnName: 'paymentDate', direction: 'desc' }])
    const pageSize = 25
    const rows = payments.data && payments.data.length > 0 ? payments.data : []
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />
    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton>
                    <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const columns = [
        { name: 'saleNo', title: 'Record No' },
        { name: 'customerFirstName', title: 'Customer Name' },
        { name: 'paymentDate', title: 'Payment Date' },
        { name: 'cashPayment', title: 'Cash Payment', getCellValue: (row) => formatCurrency(row.cashAmount) },
        {
            name: 'creditCardPayment',
            title: 'Credit Card',
            getCellValue: (row) => formatCurrency(row.creditCardAmount),
        },
        { name: 'otherPayment', title: 'Other-Pay', getCellValue: (row) => formatCurrency(row.othersAmount) },
        { name: 'transcationBy', title: 'Transaction By', align: 'left' },
        { name: 'totalPaid', title: 'Total Paid', getCellValue: (row) => formatCurrency(row.totalPaid) },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'saleNo', width: min ? '120px' : '12%', minWidth: '120px', wordWrapEnabled: true },
            { columnName: 'customerFirstName', width: min ? '150px' : '15%', minWidth: '150px', wordWrapEnabled: true },
            {
                columnName: 'paymentDate',
                width: min ? '110px' : '15%',
                minWidth: '110px',
                wordWrapEnabled: true,
                align: 'center',
            },
            {
                columnName: 'cashPayment',
                wordWrapEnabled: true,
                width: min ? '110px' : '9%',
                minWidth: '110px',
                align: 'right',
            },
            {
                columnName: 'creditCardPayment',
                width: min ? '110px' : '9%',
                minWidth: '110px',
                wordWrapEnabled: true,
                align: 'right',
            },
            {
                columnName: 'otherPayment',
                align: 'right',
                width: min ? '110px' : '9%',
                minWidth: '110px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'transcationBy',
                align: 'left',
                width: min ? '140px' : '14%',
                minWidth: '140px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'totalPaid',
                align: 'right',
                width: min ? '100px' : '8%',
                minWidth: '100px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'Action',
                align: 'center',
                wordWrapEnabled: true,
                width: min ? '90px' : '9%',
                minWidth: '90px',
            },
        ],

        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={rows} columns={columns}>
                    <DateTypeProvider for={dateColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={payments.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default PaymentsData
