import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, Label } from 'devextreme-react/form'
import { ColCountByScreen } from 'devextreme-react/data-grid'
import { getUserTypeLookupList } from '../../../../redux/store/features/users'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { useMediaQuery } from '@mui/material'

const UpdateUserForm = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    useEffect(() => {
        dispatch(getUserTypeLookupList(''))
    }, [])

    const { loading } = useSelector((state) => state.users)
    const { userTypes } = useSelector((state) => state.users)

    const userTypeStore = new DataSource({
        store: new ArrayStore({
            key: 'id',
            data: userTypes.data,
            totalCount: userTypes.totalCount,
        }),
        reshapeOnPush: true,
    })

    const userTypeLookupEditorOptions = {
        dataSource: userTypeStore,
        displayExpr: 'typeName',
        valueExpr: 'id',
        searchEnabled: true,
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/security/users'),
    }

    const validationRules = {
        firstName: [{ type: 'required', message: 'First Name is required.' }],
        lastName: [{ type: 'required', message: 'Last Name is required.' }],
        email: [
            { type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Enter avalid email.' },
        ],
        userType: [{ type: 'required', message: 'User Type is required.' }],
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(props.updateUserGrid)}>
                <Form
                    mode='form'
                    formData={props.userGrid}
                    showColonAfterLabel={false}
                    showValidationSummary={false}
                    labelLocation={labelLocation}>
                    <GroupItem>
                        <GroupItem caption='Update User Details' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={1} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <GroupItem>
                                    <SimpleItem dataField='isActive' editorType='dxCheckBox'>
                                        <Label text='User is Active' />
                                    </SimpleItem>
                                    <SimpleItem dataField='firstName' validationRules={validationRules.firstName} />
                                    <SimpleItem dataField='lastName' validationRules={validationRules.lastName} />
                                    <SimpleItem dataField='email' validationRules={validationRules.email} />
                                    <SimpleItem dataField='password' editorOptions={{ mode: 'password' }} />
                                    <SimpleItem dataField='comments' editorType='dxTextArea' />
                                    <SimpleItem dataField='birthDate' editorType='dxDateBox' />
                                    <SimpleItem
                                        dataField='userType'
                                        editorType='dxSelectBox'
                                        editorOptions={userTypeLookupEditorOptions}
                                        validationRules={validationRules.userType}
                                    />
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                    </GroupItem>
                    <EmptyItem />
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                        <ButtonItem
                            buttonOptions={{
                                disabled: loading,
                                icon: loading ? 'refresh' : 'check',
                                ...submitButtonOptions,
                            }}
                            horizontalAlignment='right'
                        />
                        <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                    </GroupItem>
                </Form>
            </form>
        </React.Fragment>
    )
}

export default UpdateUserForm
