import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { SelectBox } from 'devextreme-react'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderTypeLookupList } from 'app/redux/store/features/orders'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import supplierService from 'app/services/shipments/supplier/supplierServices'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from 'app/services/util-services'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import useDebouncedInput from 'app/pages/reports/hooks/useDebouncedInput'
import { DateBox } from 'devextreme-react/date-box'
import { useJumboTheme } from '@jumbo/hooks'
import { useMediaQuery } from '@mui/material'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'

const ApprovedOrdersGridFilter = ({ mutation, display, filterIconColor }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        dispatch(getOrderTypeLookupList(''))
    }, [])

    const { orderTypeLookups } = useSelector((state) => state.orders)

    let initialValues = {
        shipmentNo: '',
        shipmentStartDate: DateUtils.getStartDate(10).toISOString().slice(0, 10),
        shipmentEndDate: DateUtils.getEndDate().toISOString().slice(0, 10),
    }

    const [filter, setFilter] = useState({
        SupplierId: null,
        shipmentType: null,
    })

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const orderTypeStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'id',
                    data: orderTypeLookups.data,
                    totalCount: orderTypeLookups.totalCount,
                }),
                reshapeOnPush: true,
            }),
        [orderTypeLookups]
    )

    const supplierLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await supplierService.getSupplierLookupList({ supplierName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [supplierService]
    )

    const supplierLookupById = useCallback(
        async (key) => {
            try {
                return await supplierService.getSupplierById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [supplierService]
    )

    const supplierLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'supplierId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await supplierLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return supplierLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onFormSubmit = async (data) => {
        const startDateFormatted = data.shipmentStartDate ? new Date(data.shipmentStartDate).toISOString() : null
        const endDateFormatted = data.shipmentEndDate ? new Date(data.shipmentEndDate).toISOString() : null
        await mutation.mutate({
            ...filter,
            shipmentNo: data.shipmentNo,
            shipmentStartDate: startDateFormatted ? startDateFormatted.toFilterStartDate() : null,
            shipmentEndDate: endDateFormatted ? endDateFormatted.toFilterEndDate() : null,
            onlineShipments: false,
            sort: 'ShipmentDate',
            isDescending: true,
        })
    }

    const onFilterSubmit = async (data, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit(data)
        setSubmitting(false)
    }

    const handleClear = () => {
        filterIconColor(false)
        setFilter({ SupplierId: null, shipmentType: null })
    }

    const onSupplierSelectionChanged = (value) => {
        setFilter({ ...filter, SupplierId: value?.supplierId })
    }

    const {
        value: searchValue,
        debouncedValue: debouncedOrderTypeValue,
        onChange: onOrderTypeSearch,
    } = useDebouncedInput('', 300)

    useEffect(() => {
        dispatch(getOrderTypeLookupList(searchValue))
    }, [debouncedOrderTypeValue])

    const onOrderTypeSelectionChanged = (value) => {
        setFilter({ ...filter, shipmentType: value?.id })
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting, setFieldValue, values }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick
                        noWrapper
                        id='filterBody'
                        variant={'primary'}
                        sx={{ display: display, borderRadius: 0 }}>
                        <Divider />
                        <Div
                            style={{
                                display: 'flex',
                                margin: sm ? '0 1.5% 22px 1.5%' : '0 1.5% 1.5% 1.5%',
                                flexWrap: 'wrap',
                                textAlign: sm ? 'center' : '',
                                justifyContent: sm ? 'center' : '',
                            }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <DateBox
                                        type='date'
                                        onValueChanged={(e) => setFieldValue('shipmentStartDate', e.value)}
                                        value={values.shipmentStartDate}
                                        style={{ width: 220 }}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <DateBox
                                        type='date'
                                        onValueChanged={(e) => setFieldValue('shipmentEndDate', e.value)}
                                        value={values.shipmentEndDate}
                                        style={{ width: 220 }}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Order No</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='shipmentNo' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Order Type</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={orderTypeStore}
                                        displayExpr={'typeName'}
                                        value={filter.shipmentType}
                                        searchEnabled={true}
                                        onValueChange={onOrderTypeSelectionChanged}
                                        onInput={onOrderTypeSearch}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Supplier</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={supplierLookupStore}
                                        value={filter.SupplierId}
                                        displayExpr={'supplierName'}
                                        searchEnabled={true}
                                        onValueChange={onSupplierSelectionChanged}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default ApprovedOrdersGridFilter
