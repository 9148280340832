import jwtAuthAxios from '../../../auth/jwtAuth'

const customerCategoryService = {}

customerCategoryService.getCustomerCategoriesLookupList = async (filter) => {
    let url = filter?.customerCategoryName
        ? `/epos/Report/definitions/customercategories/lookup/list?search=${filter.customerCategoryName}`
        : `/epos/Report/definitions/customercategories/lookup/list`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

customerCategoryService.getCustomerCategoriesLookupById = async (key) => {
    let url = `/customercategories/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

customerCategoryService.getCustomerList = async (filter) => {
    let url = `/sales/Customer/list`
    const { data } = await jwtAuthAxios.post(url, filter).catch(function (error) {
        if (error.response) {
            console.log(error.response.data)
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

customerCategoryService.getCustomerById = async (key) => {
    let url = `/sales/Customer/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}
export default customerCategoryService
