export const defaultAddDraftCustomerPayment = {
    cashAmount: 0,
    creditCardAmount: 0,
    othersAmount: 0,
    fkCashChartAccountId: null,
    fkCreditChartAccountId: null,
    fkOtherChartAccountId: null,
    fkCustomerId: null,
    paymentReferenceNo: null,
    comments: null,
}
