import { locale } from 'devextreme/localization'

// In this formatDateTime method  date and time format with 28/06/2024,05:38:28 pm

export const formatDateTime = (input, asElement = false) => {
    // Check if input is valid
    if (!input) {
        console.error('Input is undefined or null')
        return asElement ? <span></span> : ''
    }

    const value = input && typeof input === 'object' ? input.value : input

    // Check if value is null
    if (!value) {
        console.error('Input value is falsy')
        return asElement ? <span></span> : ''
    }

    // Attempt to create a Date object
    const date = new Date(value)

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        console.error('Invalid date:', value)
        return asElement ? <span></span> : ''
    }

    // Format the date
    const formattedDate = new Intl.DateTimeFormat(locale.languageTag, {
        timeStyle: 'medium',
        dateStyle: 'short',
        hour12: true,
    }).format(date)

    // Return as a React element or as a string based on the asElement flag
    return asElement ? <span>{formattedDate}</span> : formattedDate
}

// In this separatedFormattedDateTime date and time format with 28/06/2024 1:50:36 pm

export const separatedFormattedDateTime = (input, asElement = false, locale = 'en-PK') => {
    // Check if input is valid
    if (!input) {
        console.error('Input is undefined or null')
        return asElement ? <span></span> : ''
    }

    const value = input && typeof input === 'object' ? input.value : input

    // Attempt to create a Date object
    const date = new Date(value)

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        console.error('Invalid date:', value)
        return asElement ? <span></span> : ''
    }

    // Extract the date and time parts with AM/PM
    const formattedDate = new Intl.DateTimeFormat(locale, {
        dateStyle: 'short',
    }).format(date)

    const formattedTime = new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
    }).format(date)

    // Return as a React element or as a string based on the asElement flag
    if (asElement) {
        return (
            <span>
                {formattedDate}
                <br />
                {formattedTime}
            </span>
        )
    } else {
        return `${formattedDate} ${formattedTime}`
    }
}

// In this formattedDate date  format with 28/06/2024
export const formattedDate = (input, asElement = false) => {
    const value = input && typeof input === 'object' ? input.value : input
    if (!value) return asElement ? <span></span> : ''

    const date = new Date(value)
    const formattedDateString = new Intl.DateTimeFormat(locale.languageTag, {
        dateStyle: 'short',
    }).format(date)

    // Return as a React element or as a string based on the asElement flag
    return asElement ? <span>{formattedDateString}</span> : formattedDateString
}

export const formattedDateWithoutYear = (input, asElement = false) => {
    const value = input && typeof input === 'object' ? input.value : input
    if (!value) return asElement ? <span></span> : ''

    const date = new Date(value)
    const options = {
        month: 'short', // Display abbreviated month
        day: 'numeric', // Display day
    }
    const formattedDateString = new Intl.DateTimeFormat(locale.languageTag, options).format(date)

    return asElement ? <span>{formattedDateString}</span> : formattedDateString
}

// In this formattedDate date and time format with 28 Jun 2024, 1:50 pm
export const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleString([], {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    })
}
