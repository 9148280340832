import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import { LoadPanel } from 'devextreme-react/load-panel'
import Div from '@jumbo/shared/Div'
import SupplierAuditLogGridData from './components/SupplierAuditLogGridData'
import { getSupplierAuditLogList } from 'app/redux/store/features/suppliers'

const SupplierAuditLogs = ({ supplierId }) => {
    const pageSize = 25
    const position = { of: '#dataGrid' }
    const [filter, setFilter] = useState({
        supplierId: supplierId,
        auditStartDate: DateUtils.getStartDate(10),
        auditEndDate: DateUtils.getEndDate(),
        auditByStaffName: '',
        isDescending: true,
    })

    const [currentPage, setCurrentPage] = useState(0)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const dispatch = useDispatch()
    const { supplierAuditLogs, refreshing } = useSelector((state) => state.suppliers)

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getSupplierAuditLogList({ ...filter, skip: 0, take: pageSize }))
    }, [])

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getSupplierAuditLogList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getSupplierAuditLogList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getSupplierAuditLogList({ ...filter, skip: 0, take: pageSize }, true))
        setCurrentPage(0)
    }

    return (
        <React.Fragment>
            <Grid>
                <Div id='dataGrid'>
                    <Grid>
                        <SupplierAuditLogGridData
                            onRefresh={onRefresh}
                            onPageChange={onPageChange}
                            onColumnSort={onColumnSort}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            apiCallInProgress={apiCallInProgress}
                            setApiCallInProgress={setApiCallInProgress}
                        />
                    </Grid>
                    {supplierAuditLogs.totalCount !== 0 && (
                        <LoadPanel shadingColor='rgba(0,0,0,0.4)' position={position} visible={refreshing} />
                    )}
                </Div>
            </Grid>
        </React.Fragment>
    )
}

export default SupplierAuditLogs
