import React from 'react'
import Page from '@jumbo/shared/Page'
import AuditLogGrid from 'app/pages/security/auditLog/AuditLogGrid'
import Transfers from 'app/pages/security/transfer/Transfers'
import RequestView from 'app/pages/security/transfer/components/requestView/RequestView'
import TransferView from 'app/pages/security/transfer/components/TransferView/TransferView'
import AddTransfer from 'app/pages/security/transfer/components/inventories/components/AddTransfer'
import UpdateTransfer from 'app/pages/security/transfer/components/inventories/components/UpdateTransfer'
import AuditLogView from 'app/pages/security/auditLog/components/AuditLogView'

const companyRoutes = [
    {
        path: '/company/auditlogs',
        element: <Page component={AuditLogGrid} />,
    },
    {
        path: '/company/auditlogs/details/:auditLogId',
        element: <Page component={AuditLogView} />,
    },
    {
        path: ['/company/transfers/', '/company/transfers/:selectedTab'],
        element: <Page component={Transfers} />,
    },
    {
        path: ['/company/transfers/request/details/:transferRequestId'],
        element: <Page component={RequestView} />,
    },
    {
        path: ['/company/transfers/inventories/details/:transferInventoryId'],
        element: <Page component={TransferView} />,
    },
    {
        path: ['/company/transfers/inventories/addTransfer'],
        element: <Page component={AddTransfer} />,
    },
    {
        path: '/company/transfers/inventories/edit/:transferInventoryId',
        element: <Page component={UpdateTransfer} />,
    },
]

export default companyRoutes
