import React, { useEffect, useState, useMemo } from 'react'
import {
    CustomPaging,
    DataTypeProvider,
    PagingState,
    SortingState,
    SelectionState,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid'
import { IconButton, Paper, useMediaQuery } from '@mui/material'
import { Grid, Table, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import '../../../../constants/stylesheet.css'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { getCompanyUserList } from 'app/redux/store/features/company'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import { useJumboTheme } from '@jumbo/hooks'

const LinkedUserGrid = (props) => {
    const [filter, setFilter] = useState()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { companyUsers, loading, error } = useSelector((state) => state.companies)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getCompanyUserList({ ...filter, companyId: props.companyId, skip: 0, take: pageSize }))
    }, [dispatch, filter])

    const dateColumns = ['updatedDate']
    const booleanColumns = ['isActive']
    const [sorting, setSorting] = useState([{ columnName: 'firstName', direction: 'asc' }])
    const [currentPage, setCurrentPage] = useState(0)
    const pageSize = 25

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, companyId: props.companyId, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getCompanyUserList(currentFilter))
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            companyId: props.companyId,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getCompanyUserList(currentFilter))
        setFilter(currentFilter)
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton>
                    <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const BooleanFormatter = ({ value }) => <span>{formatBooleanValue(value)}</span>
    const formatBooleanValue = (value) => (value ? 'Yes' : 'No')
    const BooleanTypeProvider = (props) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />

    const columns = [
        { name: 'firstName', title: 'First Name' },
        { name: 'lastName', title: 'Last Name' },
        { name: 'phoneNo', title: 'Phone No' },
        { name: 'email', title: 'Email' },
        { name: 'userTypeName', title: 'User Type' },
        { name: 'updatedDate', title: 'Date Updated', hidden: true },
        { name: 'isActive', title: 'Enabled' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const visibleColumns = columns.filter((column) => !column.hidden)

    const columnWidth = useMemo(
        () => [
            {
                columnName: 'firstName',
                wordWrapEnabled: true,
                width: min ? '160px' : '16%',
                minWidth: '160px',
            },
            { columnName: 'lastName', wordWrapEnabled: true, width: min ? '160px' : '16%', minWidth: '160px' },
            { columnName: 'phoneNo', wordWrapEnabled: true, width: min ? '140px' : '14%', minWidth: '140px' },
            { columnName: 'email', wordWrapEnabled: true, width: min ? '250px' : '25%', minWidth: '250px' },
            { columnName: 'userTypeName', wordWrapEnabled: true, width: min ? '130px' : '13%', minWidth: '130px' },
            {
                columnName: 'isActive',
                wordWrapEnabled: true,
                width: min ? '110px' : '8%',
                minWidth: '110px',
                align: 'center',
            },
            { columnName: 'Action', width: min ? '120px' : '8%', minWidth: '120px', align: 'center' },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [
        { columnName: 'Action', sortingEnabled: false },
        { columnName: 'userTypeName', sortingEnabled: false },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={companyUsers.data} columns={visibleColumns}>
                    <DateTypeProvider for={dateColumns} />
                    <BooleanTypeProvider for={booleanColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={companyUsers.totalCount} />
                    <Table columnExtensions={columnWidths} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default LinkedUserGrid
