export const defaultOrderItemForm = {
    fkInventoryId: null,
    fkStockStorageId: null,
    inventoryBasePrice: 1,
    qtyOrdered: 1,
    qtyZeroPrice: 0,
    orderPrice: '',
    orderPricePercentage: null,
    salePrice: '',
    salePricePercentage: null,
    wholeSaleUntiPrice: '',
    wholeSaleUntiPricePercentage: null,
    discountRate: null,
    taxRate: null,
    additionalTax: 0,
    expiryDate: null,
    marketPrice: '',
}
