import localeCurrency from 'locale-currency'
const getTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}
const timezone = getTimeZone()
const getCurrencyCode = () => {
    const locale = navigator.language // Detect user's locale
    return localeCurrency.getCurrency(locale) // Returns currency code
}

// Usage
const currency = getCurrencyCode()
const locale = navigator.language

const formatCurrency = (amount) => {
    const formattedAmount = new Intl.NumberFormat(locale.languageTag, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'symbol',
    }).format(amount)
     
    if (timezone === 'Asia/Karachi') {
        return formattedAmount.replace(/[^-\d.]+/g, '')
    }
    return formattedAmount
}

export default formatCurrency
export const formatCurrencyForm = (amount, pattern) => {
    const currency = getCurrencyCode() // Get the currency code (e.g., 'USD' for US dollars)

    const formattedAmount = new Intl.NumberFormat(locale.languageTag, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'symbol',
    })
        .format(amount)
        .replace(/^[A-Z]+/, '')
    if (pattern === '(#0.##)') {
        return `(${formattedAmount.replace(/\D/g, '')})` // Display in parentheses for negative numbers
    }
    if (timezone === 'Asia/Karachi') return formattedAmount.replace(/^\D+/, '')

    return formattedAmount
}

export const CurrencyFormatterSummary = ({ value }) => {
    const formattedAmount = new Intl.NumberFormat(locale.languageTag, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'symbol',
    }).format(value)
    if (timezone === 'Asia/Karachi') return formattedAmount.replace(/^\D+/, '')
    return formattedAmount
}
