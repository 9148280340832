import React, { useEffect, useState, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import useJumboTheme from '../../../../../../../../@jumbo/hooks/useJumboTheme'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { deleteCustomer, deleteDraftPayment, getCustomerById, getPaymentById } from 'app/redux/store/features/customers'
import Div from '@jumbo/shared/Div'
import DraftPaymentApprovedStatus from './DraftPaymentApprovedStatus/DraftPaymentApprovedStatus'

const DraftPaymentsData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const { enqueueSnackbar } = useSnackbar()
    const { payments, loading, error } = useSelector((state) => state.customers)
    const dateColumns = ['paymentDate']
    const [sorting, setSorting] = useState([{ columnName: 'paymentDate', direction: 'desc' }])
    const pageSize = 25
    const rows = payments.data && payments.data.length > 0 ? payments.data : []
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (customerPaymentId) => {
        showDialog({
            title: (
                <Div
                    sx={{
                        textAlign: 'center',
                        height: 48,
                        pt: 1.5,
                        bgcolor: 'success.main',
                        borderRadius: 1,
                        color: 'common.white',
                    }}>
                    Process Payment
                </Div>
            ),
            content: <DraftPaymentApprovedStatus customerPaymentId={customerPaymentId} />,
        })
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />
    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const onNavigatingToUpdate = (id) => {
        dispatch(getPaymentById({ customerPaymentId: id }))
        navigate(`/store/draftpayments/edit/${id}`, { state: { customerPaymentId: id } })
    }

    const renderActionCell = (row) => {
        return (
            <div>
                {row.paymentStatus === 'Draft' && (
                    <>
                        <IconButton onClick={() => onNavigatingToDetail(row.customerPaymentId)}>
                            <CheckCircleOutlineIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                        </IconButton>

                        <IconButton onClick={() => onNavigatingToUpdate(row.customerPaymentId)}>
                            <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                        </IconButton>

                        <IconButton onClick={() => onRemoveCustomerPayment(row.customerPaymentId)}>
                            <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                        </IconButton>
                    </>
                )}
                {row.paymentStatus === 'Approved' && (
                    <IconButton onClick={() => onNavigatingToUpdate(row.customerPaymentId)}>
                        <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                    </IconButton>
                )}
            </div>
        )
    }

    const onRemoveCustomerPayment = async (customerPaymentId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteDraftPayment({ customerPaymentId: customerPaymentId }))
                hideDialogAndRefreshUserList()
            },
            onNo: hideDialog,
        })
    }

    const hideDialogAndRefreshUserList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Payment removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.stockShipmentId)
                }}
            />
        )
    }

    const columns = [
        { name: 'paymentNo', title: 'Payment No' },
        { name: 'customerFirstName', title: 'Customer Name' },
        { name: 'paymentDate', title: 'Payment Date' },
        { name: 'cashPayment', title: 'Cash Payment', getCellValue: (row) => formatCurrency(row.cashAmount) },
        {
            name: 'creditCardPayment',
            title: 'Credit Card',
            getCellValue: (row) => formatCurrency(row.creditCardAmount),
        },
        { name: 'otherPayment', title: 'Other Payment', getCellValue: (row) => formatCurrency(row.othersAmount) },
        { name: 'transcationBy', title: 'Transaction By' },
        { name: 'totalPaid', title: 'Total Paid', getCellValue: (row) => formatCurrency(row.totalPaid) },
        { name: 'paymentStatus', title: 'Pay-Status' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'paymentNo', width: min ? '120px' : '10%', minWidth: '120px', wordWrapEnabled: true },
            { columnName: 'customerFirstName', width: min ? '140px' : '13%', minWidth: '140px', wordWrapEnabled: true },
            { columnName: 'paymentDate', width: min ? '120px' : '13%', minWidth: '120px', wordWrapEnabled: true },
            {
                columnName: 'cashPayment',
                wordWrapEnabled: true,
                width: min ? '110px' : '9%',
                minWidth: '110px',
                align: 'right',
            },
            {
                columnName: 'creditCardPayment',
                width: min ? '110px' : '9%',
                minWidth: '110px',
                wordWrapEnabled: true,
                align: 'right',
            },
            {
                columnName: 'otherPayment',
                align: 'right',
                width: min ? '110px' : '9%',
                minWidth: '110px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'transcationBy',
                width: min ? '130px' : '11%',
                minWidth: '130px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'totalPaid',
                align: 'right',
                width: min ? '110px' : '10%',
                minWidth: '100px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'paymentStatus',
                width: min ? '110px' : '8%',
                minWidth: '100px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'Action',
                align: 'center',
                width: min ? '110px' : '8%',
                minWidth: '110px',
            },
        ],

        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={rows} columns={columns}>
                    <DateTypeProvider for={dateColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={payments.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default DraftPaymentsData
