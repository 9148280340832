import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, ColCountByScreen, Label } from 'devextreme-react/form'
import { useMediaQuery } from '@mui/material'

const AuditLogViewForm = ({ SingleAuditLog }) => {
    const navigate = useNavigate()
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/company/auditlogs'),
    }

    const messageEditorOptions = { height: 90, maxLength: 200 }

    return (
        <React.Fragment>
            <form>
                <Form
                    mode='form'
                    labelLocation={labelLocation}
                    formData={SingleAuditLog}
                    showColonAfterLabel={false}
                    showValidationSummary={false}
                    readOnly={true}>
                    <EmptyItem />
                    <GroupItem cssClass='form-group-item' caption='Audit Log Details'>
                        <ColCountByScreen lg={13} md={1} sm={1} xs={1} />
                        <GroupItem />
                        <GroupItem />
                        <GroupItem />
                        <GroupItem cssClass='form-group-item' colSpan={6}>
                            <EmptyItem />
                            <SimpleItem
                                dataField='auditDate'
                                editorType='dxDateBox'
                                editorOptions={{
                                    displayFormat: 'shortDateShortTime',
                                    type: 'datetime',
                                    pickerType: 'calendar',
                                    useMaskBehavior: true,
                                }}>
                                <Label text='Audit Date' />
                            </SimpleItem>
                            <SimpleItem dataField='referenceDate' editorType='dxDateBox'>
                                <Label text='Refrence Date' />
                            </SimpleItem>
                            <SimpleItem dataField='branchName'>
                                <Label text='Branch' />
                            </SimpleItem>
                            <SimpleItem dataField='auditType'>
                                <Label text='Audit Type' />
                            </SimpleItem>
                            <SimpleItem dataField='auditSource'>
                                <Label text='Audit Source' />
                            </SimpleItem>
                            <SimpleItem dataField='reference'>
                                <Label text='Refrence' />
                            </SimpleItem>
                            <SimpleItem
                                dataField='message'
                                editorType='dxTextArea'
                                editorOptions={messageEditorOptions}>
                                <Label text='Message' />
                            </SimpleItem>
                        </GroupItem>
                    </GroupItem>
                    <EmptyItem />
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                        <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='right' />
                    </GroupItem>
                    <EmptyItem />
                </Form>
            </form>
        </React.Fragment>
    )
}

export default AuditLogViewForm
