import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Span from '@jumbo/shared/Span'
import { Grid } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import MoveUpIcon from '@mui/icons-material/MoveUp'
import { VscReferences } from 'react-icons/vsc'
import PaidIcon from '@mui/icons-material/Paid'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const TransferHeaderCard = ({ transfer }) => {
    return (
        <Grid>
            <Grid container spacing={3.75}>
                <Grid item xs={12} sm={6} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack
                                direction={'row'}
                                alignItems='center'
                                justifyContent='center'
                                spacing={2}
                                sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Typography
                                    fontFamily='Lobster, cursive'
                                    color='common.white'
                                    sx={{
                                        fontSize: {
                                            xs: '1.5rem',
                                            md: '1.2rem',
                                        },
                                    }}>
                                    {transfer?.transferNo ?? ''}{' '}
                                    <TransferWithinAStationIcon
                                        color='common.white'
                                        sx={{ fontSize: 27, mb: -0.5, mr: 1.5 }}
                                    />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'success.dark',
                        }}>
                        <Typography fontFamily='Lobster, cursive' color='success.dark' variant={'h4'}>
                            <DateRangeIcon fontSize='large' sx={{ mt: -3, mb: -1, ml: 1 }} />
                            <Span sx={{ ml: 1 }}>{`${formatDateTime(transfer?.transferRequiredDate ?? '')}`}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack
                                direction={'row'}
                                alignItems='center'
                                justifyContent='center'
                                spacing={2}
                                sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Typography
                                    fontFamily='Lobster, cursive'
                                    color='common.white'
                                    sx={{
                                        fontSize: {
                                            xs: '1.5rem',
                                            md: '1.2rem',
                                        },
                                    }}>
                                    {' '}
                                    Transfer Status{' '}
                                    <MoveUpIcon color='common.white' sx={{ fontSize: 27, mb: -0.5, mr: 1.5 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'warning.main',
                        }}>
                        <Typography variant={'h4'} fontFamily='Lobster, cursive' color='warning.main'>
                            <CheckCircleOutlineIcon fontSize='large' sx={{ mt: -3, mb: -1, ml: 1 }} />
                            <Span sx={{ ml: 1 }}>{transfer.transferStatusName}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack
                                direction={'row'}
                                alignItems='center'
                                justifyContent='center'
                                spacing={2}
                                sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Typography
                                    fontFamily='Lobster, cursive'
                                    color='common.white'
                                    sx={{
                                        fontSize: {
                                            xs: '1.5rem',
                                            md: '1.2rem',
                                        },
                                    }}>
                                    Reference Number{' '}
                                    <VscReferences color='common.white' sx={{ fontSize: 27, mb: -0.5, mr: 1.5 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'info.main',
                        }}>
                        <Typography color='info.dark' variant={'h4'} fontFamily='Lobster, cursive'>
                            <Span sx={{ ml: 1 }}>{transfer.refNumber ? transfer.refNumber : '-'}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack
                                direction={'row'}
                                alignItems='center'
                                justifyContent='center'
                                spacing={2}
                                sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Typography
                                    fontFamily='Lobster, cursive'
                                    color='common.white'
                                    sx={{
                                        fontSize: {
                                            xs: '1.5rem',
                                            md: '1.2rem',
                                        },
                                    }}>
                                    Sub Total <LocalOfferIcon color='common.white' sx={{ ml: 1 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'success.main',
                        }}>
                        <Typography variant={'h4'} fontFamily='Lobster, cursive' color='success.dark'>
                            <PaidIcon fontSize='large' sx={{ mt: -3, mb: -1, mr: 1 }} />
                            <Span sx={{ ml: 1 }}>{formatCurrency(transfer?.subTotal ?? '')}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TransferHeaderCard
