export const defaultDraftOrder = {
    purchaseOrderNo: '',
    fkSupplierId: null,
    comments: '',
    freightAmount: 0,
    freightBNumber: '',
    freightComments: '',
    isDiscountFixed: false,
    isDiscountEach: false,
    freightAmountPaidByType: 0,
    comments: '',
}
