import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import DataSource from 'devextreme/data/data_source'
import { DropDownBox } from 'devextreme-react'
import { useSnackbar } from 'notistack'
import DataGrid, { FilterRow, Paging, Selection } from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from 'app/services/util-services'
import inventoryService from 'app/services/pages/store/inventory/inventoryServices'
import { useNavigate } from 'react-router-dom'
import { buildFilter } from 'app/utils/appHelpers'
import definitionServices from 'app/services/pages/store/definitions/definitionServices'
import InventoryBasePriceService from './InventoryBasePrice'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import { defaultOrderItemForm } from './defaultOrderItemForm'
import { Formik } from 'formik'
import { useMediaQuery } from '@mui/material'
import { Validator, RequiredRule } from 'devextreme-react/validator'

const OrderItemAddHeader = ({ onClickAdd, onClickSave, isDraftStatus }) => {
    const navigate = useNavigate()
    const [labelLocation, setLabelLocation] = useState('left')
    const inventoryDropDownRef = useRef()
    const { enqueueSnackbar } = useSnackbar()
    const [filter, setFilter] = useState({
        fkStockStorageId: null,
    })
    const [orderItemFormData, setOrderItemFormData] = useState(() => ({ ...defaultOrderItemForm }))
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    useEffect(() => {
        setOrderItemFormData({ ...defaultOrderItemForm })
    }, [defaultOrderItemForm])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const ownerLabel = { 'aria-label': 'Owner' }
    const gridColumns = [
        { dataField: 'barcode' },
        { dataField: 'inventoryName' },
        { dataField: 'totalStock', allowFiltering: false },
    ]
    const gridBoxDisplayExpr = useCallback((item) => {
        return item && `${item.barcode}, ${item.inventoryName}, ${item.totalStock}`
    }, [])

    const inventoryLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    loadMode: 'processed',
                    key: 'inventoryId',
                    load: async function (loadOptions) {
                        let searchTerm = { skip: 0, take: 10 }
                        if (loadOptions?.filter) {
                            const filter = buildFilter(loadOptions.filter)
                            searchTerm = { ...searchTerm, ...filter }
                        }
                        return await inventoryLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (utilServices.isNullOrUndefined(key) || key === '') {
                            return {}
                        }
                        return await inventoryLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const inventoryLookupList = useCallback(
        async (filter) => {
            try {
                return await inventoryService.getInventoryList(filter)
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryLookupStore]
    )

    const inventoryLookupById = useCallback(async (key) => {
        if (key.length === 0 || key[0] === '') {
            return utilServices.emptyList()
        }

        try {
            return await inventoryService.getInventoryById({ invClassId: key })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const [gridBoxValue, setGridBoxValue] = useState([''])
    const [isGridBoxOpened, setIsGridBoxOpened] = useState(false)
    const dataGridOnSelectionChanged = useCallback((e) => {
        setGridBoxValue(e.selectedRowKeys)
        setIsGridBoxOpened(false)
    }, [])

    const useSubmitBehaviors = gridBoxValue.length > 0 && gridBoxValue[0] !== ''

    const dataGridRender = useCallback(
        () => (
            <DataGrid
                dataSource={inventoryLookupStore}
                columns={gridColumns}
                columnAutoWidth={true}
                hoverStateEnabled={true}
                showBorders={true}
                selectedRowKeys={gridBoxValue}
                onSelectionChanged={dataGridOnSelectionChanged}
                height='100%'
                remoteOperations={true}>
                <Selection mode='single' />
                <Paging enabled={false} pageSize={10} />
                <FilterRow visible={true} applyFilter='auto' />
            </DataGrid>
        ),
        [gridBoxValue, dataGridOnSelectionChanged]
    )

    const syncDataGridSelection = useCallback(
        async (e, setFieldValue) => {
            try {
                const inventory = await inventoryLookupById(e.value)
                if (inventory) {
                    setFieldValue('orderPrice', inventory.purchasePrice || '')
                    setFieldValue('salePrice', inventory.salePrice || '')
                    setFieldValue('wholeSaleUntiPrice', inventory.wholesalePrice || '')
                    setFieldValue('orderPricePercentage', inventory.orderPricePercentage || '')
                    setFieldValue('salePricePercentage', inventory.salePricePercentage || '')
                    setFieldValue('wholeSaleUntiPricePercentage', inventory.wholeSaleUntiPricePercentage || '')
                    setFieldValue('purchaseTaxRate', inventory.purchaseTaxRate || '')
                    setFieldValue('marketPrice', inventory.marketPrice || '')
                    setFieldValue('inventoryBasePrice', inventory.inventoryBasePrice || 1)

                    // If the inventory dropdown is focused, close it after selection
                    if (inventoryDropDownRef.current?.instance) {
                        inventoryDropDownRef.current.instance.focus()
                    }
                } else {
                    setFieldValue('orderPrice', orderItemFormData.orderPrice)
                    setFieldValue('salePrice', orderItemFormData.salePrice)
                    setFieldValue('wholeSaleUntiPrice', orderItemFormData.wholeSaleUntiPrice)
                    setFieldValue('orderPricePercentage', orderItemFormData.orderPricePercentage)
                    setFieldValue('salePricePercentage', orderItemFormData.salePricePercentage)
                    setFieldValue('wholeSaleUntiPricePercentage', orderItemFormData.wholeSaleUntiPricePercentage)
                    setFieldValue('purchaseTaxRate', orderItemFormData.purchaseTaxRate)
                    setFieldValue('marketPrice', orderItemFormData.marketPrice)
                    setFieldValue('inventoryBasePrice', orderItemFormData.inventoryBasePrice)

                    if (inventoryDropDownRef.current?.instance) {
                        inventoryDropDownRef.current.instance.focus()
                    }
                }
            } catch (error) {
                handleError(error) // Handle any errors that occur during the lookup
            }
        },
        [inventoryLookupById, handleError]
    )

    const onGridBoxOpened = useCallback((e) => {
        if (e.name === 'opened') {
            setIsGridBoxOpened(e.value)
        }
    }, [])

    const stockStorageLookupList = useCallback(async (searchTerm) => {
        try {
            return await definitionServices.getStockStorageLookupList({
                storageName: searchTerm,
            })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const stockStorageLookupById = useCallback(async (key) => {
        if (key === null) {
            return utilServices.emptyList()
        }
        try {
            return await definitionServices.getStockStorageLookupListById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const StockStorageLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'stockStorageId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await stockStorageLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await stockStorageLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onFormSubmit = async (values, { resetForm }) => {
        const inventory = await inventoryLookupById(gridBoxValue)
        const inventoryStock = await stockStorageLookupById(filter?.fkStockStorageId)
        const inventoryBasePriceData = values.inventoryBasePrice
        const qtyOrdered = values.qtyOrdered
        const qtyZeroPrice = values.qtyZeroPrice
        const orderPrice = values.orderPrice
        const orderPricePercentage = values.orderPricePercentage
        const salePrice = values.salePrice
        const salePricePercentage = values.salePricePercentage
        const wholeSaleUntiPrice = values.wholeSaleUntiPrice
        const wholeSaleUntiPricePercentage = values.wholeSaleUntiPricePercentage
        const discountRate = values.discountRate
        const purchaseTaxRate = values.purchaseTaxRate
        const additionalTax = values.additionalTax
        const expiryDate = values.expiryDate
        const marketPrice = values.marketPrice

        onClickAdd({
            inventory,
            inventoryStock,
            inventoryBasePriceData,
            qtyOrdered,
            qtyZeroPrice,
            orderPrice,
            orderPricePercentage,
            salePrice,
            salePricePercentage,
            wholeSaleUntiPrice,
            wholeSaleUntiPricePercentage,
            discountRate,
            purchaseTaxRate,
            additionalTax,
            expiryDate,
            marketPrice,
        })
        resetForm()
        setGridBoxValue([''])
    }

    const onFilterSubmit = async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true)
        await onFormSubmit(values, formikHelpers)
        formikHelpers.setSubmitting(false)
    }

    const handleSave = (e) => {
        onClickSave()
    }

    const StockStorageIdEditorOptions = {
        dataSource: StockStorageLookupStore,
        displayExpr: 'storageName',
        valueExpr: 'stockStorageId',
        showClearButton: true,
    }

    const BasePriceEditorOptions = {
        dataSource: InventoryBasePriceService.getBasePriceService(),
        displayExpr: 'inventoryBasePrice',
        valueExpr: 'id',
        showClearButton: true,
    }

    const validationRules = {
        qtyOrdered: [{ type: 'required', message: 'Quantity Received is required.' }],
        inventoryBasePrice: [{ type: 'required', message: 'Base Price is required.' }],
    }

    const handleClearOrder = (resetForm) => {
        resetForm()
        setGridBoxValue([''])
    }

    return (
        <Formik initialValues={orderItemFormData} onSubmit={onFilterSubmit}>
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
                <form onSubmit={handleSubmit}>
                    <Form
                        mode='form'
                        labelLocation={labelLocation}
                        formData={values}
                        showColonAfterLabel={false}
                        readOnly={isDraftStatus == 'Approved' ? true : false}>
                        <GroupItem>
                            <GroupItem>
                                <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                                <GroupItem caption='Prices' cssClass='form-group-item'>
                                    <GroupItem cssClass='form-group-item-margin'>
                                        <SimpleItem dataField='inventoryId'>
                                            <Label text='Inventory' />
                                            <DropDownBox
                                                readOnly={isDraftStatus == 'Approved' ? true : false}
                                                ref={inventoryDropDownRef}
                                                value={gridBoxValue}
                                                opened={isGridBoxOpened}
                                                valueExpr='inventoryId'
                                                deferRendering={false}
                                                inputAttr={{ 'aria-label': 'Owner' }}
                                                displayExpr={gridBoxDisplayExpr}
                                                placeholder='Select a value...'
                                                dataSource={inventoryLookupStore}
                                                onValueChanged={(e) => syncDataGridSelection(e, setFieldValue)}
                                                onOptionChanged={onGridBoxOpened}
                                                contentRender={dataGridRender}>
                                                <Validator>
                                                    {isDraftStatus !== 'Approved' && (
                                                        <RequiredRule message='Inventory is required' />
                                                    )}
                                                </Validator>
                                            </DropDownBox>
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='inventoryBasePrice'
                                            editorType='dxSelectBox'
                                            editorOptions={BasePriceEditorOptions}
                                            validationRules={validationRules.inventoryBasePrice}>
                                            <Label text='Inventory Base Price' />
                                        </SimpleItem>
                                        <SimpleItem dataField='orderPrice'>
                                            <Label text='Order Price' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='orderPricePercentage'
                                            editorType='dxNumberBox'
                                            editorOptions={{ min: 0 }}>
                                            <Label text='Order Price %' />
                                        </SimpleItem>
                                        <SimpleItem dataField='salePrice'>
                                            <Label text='Sale Price' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='salePricePercentage'
                                            editorType='dxNumberBox'
                                            editorOptions={{ min: 0 }}>
                                            <Label text='Sale Price %' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='wholeSaleUntiPrice'
                                            editorType='dxNumberBox'
                                            editorOptions={{ min: 0 }}>
                                            <Label text='Wholesale Price' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='wholeSaleUntiPricePercentage'
                                            editorType='dxNumberBox'
                                            editorOptions={{ min: 0 }}>
                                            <Label text='Wholesale Price %' />
                                        </SimpleItem>
                                        <SimpleItem dataField='marketPrice' editorType='dxNumberBox'>
                                            <Label text='Market Price' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                                <GroupItem>
                                    <GroupItem caption='Quantity' cssClass='form-group-item '>
                                        <GroupItem cssClass='form-group-item-margin'>
                                            <SimpleItem
                                                dataField='qtyOrdered'
                                                validationRules={validationRules.qtyOrdered}
                                                editorType='dxNumberBox'
                                                editorOptions={{ min: 1 }}>
                                                <Label text='Quantity Received' />
                                            </SimpleItem>
                                            <SimpleItem
                                                dataField='qtyZeroPrice'
                                                editorType='dxNumberBox'
                                                editorOptions={{ min: 0 }}>
                                                <Label text='Bonus Quantity' />
                                            </SimpleItem>
                                        </GroupItem>
                                    </GroupItem>
                                    <GroupItem caption='Tax & Discount' cssClass='form-group-item '>
                                        <GroupItem cssClass='form-group-item-margin'>
                                            <SimpleItem dataField='discountRate'>
                                                <Label text='Discount' />
                                            </SimpleItem>
                                            <SimpleItem dataField='purchaseTaxRate'>
                                                <Label text='Tax Rate' />
                                            </SimpleItem>
                                            <SimpleItem dataField='additionalTax'>
                                                <Label text='Additional Tax' />
                                            </SimpleItem>
                                            <SimpleItem
                                                dataField='fkStockStorageId'
                                                editorType='dxSelectBox'
                                                editorOptions={StockStorageIdEditorOptions}>
                                                <Label text='Storage Location' />
                                            </SimpleItem>
                                            <SimpleItem dataField='expiryDate' editorType='dxDateBox'>
                                                <Label text='Expiry Date' />
                                            </SimpleItem>
                                        </GroupItem>
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    text: 'Add',
                                    type: 'default',
                                    width: 120,
                                    disabled: isDraftStatus == 'Approved' ? true : false,
                                    useSubmitBehavior: useSubmitBehaviors,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem
                                buttonOptions={{
                                    text: 'Clear',
                                    type: 'Normal',
                                    width: 120,
                                    disabled: isDraftStatus == 'Approved' ? true : false,
                                    useSubmitBehavior: false,
                                    onClick: () => handleClearOrder(resetForm),
                                }}
                                horizontalAlignment='left'
                            />
                        </GroupItem>
                    </Form>
                </form>
            )}
        </Formik>
    )
}

export default OrderItemAddHeader
