import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    orders: { data: [], totalCount: 0 },
    orderTypeLookups: { data: [], totalCount: 0 },
    loading: false,
    error: null,
    refreshing: false,
}

const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getOrders: (state, action) => {
            state.orders = action.payload
            state.loading = false
            state.refreshing = false
        },
        getOrderTypeLookup: (state, action) => {
            state.orderTypeLookups.data = action.payload
            if (state.orderTypeLookups.data) state.orderTypeLookups.totalCount = state.orderTypeLookups.data.length
            state.loading = false
        },
        getExistingOrderTypeLookupById: (state, action) => {
            const index = state.orderTypeLookups.data.findIndex((order) => order.id === action.payload.id)
            if (index >= 0) state.orderTypeLookups.data[index] = action.payload
            else {
                state.orderTypeLookups.data.push(action.payload)
                state.orderTypeLookups.totalCount++
            }
            state.loading = false
        },
        getExistingOrderById: (state, action) => {
            const index = state.orders.data.findIndex((order) => order.orderId === action.payload.orderId)
            if (index >= 0) state.orders.data[index] = action.payload
            else {
                state.orders.data.push(action.payload)
                state.orders.totalCount++
            }
            state.loading = false
        },
        getShipmentItems: (state, action) => {
            if (action.payload.data.length > 0) {
                const index = state.orders.data.findIndex((order) => order.orderId === action.payload.data[0].fkOrderId)
                if (index >= 0) {
                    state.orders.data[index].inventoryItems = {
                        totalCount: action.payload.data.length,
                        data: action.payload.data,
                    }
                } else {
                    // Optional: Handle case where order does not exist in state.orders.data
                }
            }
            state.loading = false
        },

        getTxnRecalculate: (state, action) => {
            const index = state.orders.data.findIndex((item) => item.orderId === action.payload.orderId)
            if (index >= 0) {
                state.orders.data[index].inventoryItems = action.payload.inventoryItems
            }
            //We cannot add item if the transaction does not exist
            else {
                //     state.transactions.data.push(action.payload)
                //     state.transactions.totalCount++
            }
            state.loading = false
        },
        addNewOrder: (state, action) => {
            state.orders.data.push(action.payload)
            state.orders.totalCount++
            state.loading = false
        },
        updateExistingOrder: (state, action) => {
            const index = state.orders.data.findIndex((order) => order.orderId === action.payload.orderId)
            if (index >= 0) state.orders.data[index] = action.payload
            state.loading = false
        },
        removeOrder: (state, action) => {
            const index = state.orders.data.findIndex((order) => order.orderId === action.payload.orderId)
            if (index >= 0) {
                state.orders.data.splice(index, 1)
                state.orders.totalCount--
            }
            state.loading = false
        },
        addUpdateOrderItemsList: (state, action) => {
            const index = state.orders.data.findIndex((item) => item.orderId === action.payload.orderId)
            if (index >= 0) {
                state.orders.data[index].inventoryItems = action.payload.inventoryItems
            }
            //We cannot add item if the transaction does not exist
            else {
                //     state.transactions.data.push(action.payload)
                //     state.transactions.totalCount++
            }
            state.loading = false
        },
        updateExistingOrderStatus: (state, action) => {
            const index = state.orders.data.findIndex((order) => order.orderId === action.payload.orderId)
            if (index >= 0) {
                // We don't want to loose items when updating status
                const items = state.orders.data[index].inventoryItems
                state.orders.data[index] = action.payload
                state.orders.data[index].inventoryItems = items
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.orderId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    apiRequested,
    refreshRequested,
    apiRequestedFailed,
    getOrders,
    getOrderTypeLookup,
    getExistingOrderTypeLookupById,
    getExistingOrderById,
    getShipmentItems,
    addNewOrder,
    removeOrder,
    updateExistingOrder,
    addUpdateOrderItemsList,
    getTxnRecalculate,
    updateExistingOrderStatus,
} = orderSlice.actions
export default orderSlice.reducer

export const getOrderList = (filter, refresh = false) => {
    let url = '/shipment/list'
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getOrders.type,
        onError: apiRequestedFailed.type,
    })
}

export const getOrderTypeLookupList = (filter) => {
    let url = filter ? `/shipment/type/lookup/list?search=${filter}` : `/shipment/type/lookup/list`

    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getOrderTypeLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getOrderTypeLookupById = (filter) => {
    let url = `/shipment/type/lookup/${filter.stockShipmentId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingOrderTypeLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getOrderById = (filter) => {
    let url = `/shipment/${filter.orderId}?onlineShipment=${filter.onlineShipment}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingOrderById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getShipmentItemsList = (filter) => {
    let url = `/shipment/${filter.orderId}/items`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getShipmentItems.type,
        onError: apiRequestedFailed.type,
    })
}

export const addOrder = (order) => {
    let url = `/shipment/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: order,
        onStart: apiRequested.type,
        onSuccess: addNewOrder.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateOrder = (order) => {
    let url = `/shipment/update`
    return apiCallBegan({
        url: url,
        method: 'Post',
        data: order,
        onStart: apiRequested.type,
        onSuccess: updateExistingOrder.type,
        onError: apiRequestedFailed.type,
    })
}

export const deleteOrder = (order) => {
    let url = '/shipment/remove'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: order,
        onStart: apiRequested.type,
        onSuccess: removeOrder.type,
        onError: apiRequestedFailed.type,
    })
}
export const AddUpdateOrderItems = (order) => {
    let url = `/shipment/update/items`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: order,
        onStart: apiRequested.type,
        onSuccess: addUpdateOrderItemsList.type,
        onError: apiRequestedFailed.type,
    })
}
export const getOrderTxnRecalculate = (filter) => {
    let url = `/shipment/order/recalculate`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTxnRecalculate.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateOrderStatus = (order) => {
    let url = `/shipment/update/status`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: order,
        onStart: apiRequested.type,
        onSuccess: updateExistingOrderStatus.type,
        onError: apiRequestedFailed.type,
    })
}
