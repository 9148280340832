import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import RadioGroup from 'devextreme-react/radio-group'
import FreightAmountPaidByTypeService from './FreightAmountPaidByTypeService'
import { Divider } from '@mui/material'
import { useMediaQuery } from '@mui/material'

const AddDraftOrderForm = (props) => {
    const navigate = useNavigate()
    const { loading } = useSelector((state) => state.orders)
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/store/shipments/draftorders'),
    }

    const priorityEntitiesDiscountFixed = [
        { id: true, text: 'Discount Fixed' },
        { id: false, text: 'Discount Percentage' },
    ]

    const onSelectionChangedDiscountFixed = (e) => {
        props.draftOrderData.isDiscountFixed = e.value
    }

    const priorityEntitiesDiscountEach = [
        { id: true, text: 'Discount Each Item' },
        { id: false, text: 'Discount Single Item' },
    ]

    const onSelectionChangedDiscountEach = (e) => {
        props.draftOrderData.isDiscountEach = e.value
    }

    const FreightAmountPaidByTypeEditorOptions = {
        dataSource: FreightAmountPaidByTypeService.getFreightAmountPaidByType(),
        displayExpr: 'freightAmountPaidByType',
        valueExpr: 'id',
        showClearButton: true,
    }

    const validationRules = {
        orderDate: [{ type: 'required', message: 'Order Date is required.' }],
        supplier: [{ type: 'required', message: 'Supplier is required.' }],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Add Draft Order
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.addDraftOrder)}>
                    <Form
                        mode='form'
                        formData={props.draftOrderData}
                        showColonAfterLabel={false}
                        labelLocation={labelLocation}>
                        <GroupItem>
                            <GroupItem>
                                <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                                <GroupItem caption='Order Details' cssClass='form-group-item'>
                                    <GroupItem cssClass='form-group-item-margin'>
                                        <SimpleItem
                                            editorType='dxDateBox'
                                            dataField='orderDate'
                                            validationRules={validationRules.orderDate}
                                            editorOptions={{
                                                displayFormat: 'shortDateShortTime',
                                                type: 'datetime',
                                                pickerType: 'calendar',
                                                useMaskBehavior: true,
                                            }}>
                                            <Label text='Order Date' />
                                        </SimpleItem>
                                        <SimpleItem dataField='purchaseOrderNo'>
                                            <Label text='Purchase Order No' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='expectedDate'
                                            editorType='dxDateBox'
                                            editorOptions={{
                                                displayFormat: 'shortDateShortTime',
                                                type: 'datetime',
                                                pickerType: 'calendar',
                                                useMaskBehavior: true,
                                            }}>
                                            <Label text='Delivery Date' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='fkSupplierId'
                                            editorType='dxSelectBox'
                                            validationRules={validationRules.supplier}
                                            editorOptions={props.supplierEditorOptions}>
                                            <Label text='Supplier' />
                                        </SimpleItem>
                                        <SimpleItem dataField='comments' editorType='dxTextArea'>
                                            <Label text='Comments' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                                <GroupItem>
                                    <GroupItem caption='Freight Charges' cssClass='form-group-item '>
                                        <GroupItem cssClass='form-group-item-margin'>
                                            <SimpleItem dataField='freightAmount' editorType='dxNumberBox'>
                                                <Label text='Freight Amount' />
                                            </SimpleItem>
                                            <SimpleItem dataField='freightBNumber'>
                                                <Label text='Bity Number' />
                                            </SimpleItem>
                                            <SimpleItem
                                                editorType='dxSelectBox'
                                                dataField='freightAmountPaidByType'
                                                editorOptions={FreightAmountPaidByTypeEditorOptions}
                                            />
                                            <SimpleItem dataField='freightComments'>
                                                <Label text='Bity Provider' />
                                            </SimpleItem>
                                        </GroupItem>
                                    </GroupItem>
                                    <GroupItem caption='Configuration' cssClass='form-group-item '>
                                        <GroupItem cssClass='form-group-item-margin'>
                                            <SimpleItem dataField='isDiscountFixed' label={{ visible: false }}>
                                                <RadioGroup
                                                    defaultValue={props.draftOrderData.isDiscountFixed}
                                                    items={priorityEntitiesDiscountFixed}
                                                    valueExpr='id'
                                                    displayExpr='text'
                                                    layout='vertical !important'
                                                    onValueChanged={onSelectionChangedDiscountFixed}></RadioGroup>
                                            </SimpleItem>
                                            <GroupItem>
                                                <Divider sx={{ my: 0.5 }} />
                                            </GroupItem>
                                            <SimpleItem dataField='isDiscountEach' label={{ visible: false }}>
                                                <RadioGroup
                                                    items={priorityEntitiesDiscountEach}
                                                    valueExpr='id'
                                                    displayExpr='text'
                                                    layout='vertical !important'
                                                    defaultValue={props.draftOrderData.isDiscountEach}
                                                    onValueChanged={onSelectionChangedDiscountEach}></RadioGroup>
                                            </SimpleItem>
                                        </GroupItem>
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddDraftOrderForm
